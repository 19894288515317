.my-team-container {
    display: flex;
    align-items: center;
    row-gap: 75px;
    column-gap: 8px;
    flex-wrap: wrap;
    margin-top: 65px;
}


.card-information {
    background-color: #FFFFFF;
    width: 340px;
    height: 330px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 26px;
    position: relative;


    .card-profile {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: center;

        img {
            height: 100px;
            width: 100px;
            border-radius: 50px;
            object-fit: cover;
            object-position: top;
        }
    }

    .card-details {
        padding-top: 75px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-left: 25px;
        grid-row-gap: 10px;

        .details-title {
            font-size: 16px;
            font-weight: 300;
            padding-bottom: 10px;
            color: #000;
        }

        .details-reslut {
            font-weight: 400;
            font-size: 16px;
            color: #000;
        }
    }

    .my-team-performance-icon {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 10px;
        background-color: #f5f7f9;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
}