.internal-job-post-card {
    width: 300px;
    // height: 355px;
    min-height: 320px;
    background-color: #FFFFFF;
    border-radius: 23px;
    padding: 10px;

    .job-detail-container {
        border-radius: 15px;
        padding: 10px;
        background-image: url(../../../assets//images//internalJobPost/apply.png);
        background-position: right bottom;
        background-repeat: no-repeat;

        .start-date {
            background-color: #ffffff;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 15px;
            color: #000000;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .job-title {
            font-size: 23px;
            font-weight: 600;
            color: #000000;
            padding: 25px 0 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .job-type {
        border: 1px solid #A0A1A4;
        display: table;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 0;
    }

    .end-date {
        border: 1px solid #A0A1A4;
        display: table;
        padding: 5px 10px;
        border-radius: 15px;
        font-size: 14px;
        font-weight: 500;
        margin: 10px 0;
    }



}

.job-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 12px;

    .price-block {
        display: flex;
        gap: 10px;
        align-items: center;

        .amount {
            color: #000000;
            font-size: 16px;
            font-weight: 500;
        }
    }

    .months {
        font-size: 15px;
        color: #9B9B9B;
        font-weight: 500;
    }
}

.job-post-btn {
    background-color: #ffffff;
    display: table;
    color: #013979;
    border: 1px solid #013979;
    border-radius: 23px;
    padding: 5px 20px;
    height: 35px;

    &:hover {
        color: #013979;
        border: 1px solid #013979;
        background-color: #ffffff;
    }

    &:active {
        background-color: #ffffff !important;
        color: #013979 !important;
        border: 1px solid #013979 !important;
    }
}

.img-box {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    // background-color: #F8F0EB;
    border-radius: 50%;
}

//     }

// }


/*===============================
internal job post detail page css
=================================*/

.internal-job-post-information {

    border-radius: 25px;
    padding: 15px;
    // background-color: #F8F0EB;
    background-image: url(../../../assets//images/internalJobPost/apply.png);
    background-repeat: no-repeat;
    background-position: bottom right;

    min-height: calc(100vh - 61px - 50px - 5px - 30px);

    .job-title-box {
        background-color: #FFFFFF;
        height: 80px;
        padding: 10px 20px;
        border-radius: 20px;
        display: flex;
        align-items: center;

        .job-title {
            font-size: 18px;
            font-weight: 600;
            color: #000000;
        }
    }


    .jop-post-details {
        padding: 10px 20px;
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .job-post-detail-content {
            .job-sub-title {
                font-size: 15px;
                font-weight: 500;
                color: #6c6c6c;
                margin-bottom: 5px;
            }

            .job-result-info {
                font-size: 15px;
                font-weight: 600;
                color: #000000;
            }
        }
    }

    @media(min-width: 768px) and (max-width : 998px) {
        .jop-post-details {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (min-width: 768px) and (max-width: 997px) {
    .internal-job-post-information {
        height: 300px; 
        margin-bottom: 10px;
    }
}

.internal-job-post-description {
    position: relative;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    min-height: calc(100vh - 61px - 50px - 5px - 30px);

    .internal-job-post-results {
        height: 75vh;
        overflow-y: scroll;
    }

    .button-container {
        position: absolute;
        bottom: 15px;
        right: 10px;
    }
}