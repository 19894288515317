.document-card-list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 15px;
    grid-column-gap: 15px;
    padding: 8px;

    .document-card {
        background: #fff;
        border-radius: 10px;
        padding: 8px;
        transition: 0.3s;
        text-align: left;
        cursor: pointer;
        // width: 290px;
        // height: 122px;
        border: 1px solid #E1E5E9;

        &:hover {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
            transform: translateY(-3px);
            background: #B4C5D8; // Blue background on hover

        }

        .document-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .doc-id {
                background: #F7E1A3;
                color: #333;
                padding: 3px 9px;
                border-radius: 8px;
                display: inline-block;
                margin-bottom: 10px;
                font-size: small;
            }
        }

        .document-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            h3 {
                margin: 10px 0;
                font-size: 18px;
                color: #333;
            }

        }

        .document-footer {
            .view-btn {
                background-color: #FAFAFF; 
                color: #000000;
                padding: 3px 8px;
                border-radius: 200px;
                border: 1px solid #E1E5E9;
                cursor: pointer;
                transition: 0.2s;
                width: 85px;
                height: 35px;
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: space-around;
                font-weight: 500;
                font-size: 14px;
            }

        }
    }
}