.file_repository_wrapper {
    .file_repository_header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 30px;
        padding: 30px 12px;

        .title {
            h5 {
                color: #000;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }

        .file_repo_menu_list_wrapper {
            display: flex;
            align-items: center;
            gap: 14px;

            .create_folder {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #013979;
                border-radius: 6px;
                padding: 2px 8px 8px 8px;
                width: 30px;
                height: 30px;
                cursor: pointer;

                .file_repo_menu_icon {
                    color: #013979;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }


    }

    .file_repository_header_master {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0px;
        padding: 12px;

        .title {
            h5 {
                color: #000;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 0;
            }
        }

        .file_repo_menu_list_wrapper {
            display: flex;
            align-items: center;
            gap: 14px;

            .create_folder {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #013979;
                border-radius: 6px;
                padding: 2px 8px 8px 8px;
                width: 30px;
                height: 30px;
                cursor: pointer;

                .file_repo_menu_icon {
                    color: #013979;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }


    }

    .file_repository_folders_list {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 20px;
        grid-row-gap: 60px;
        margin-bottom: 30px;


        .folder_card {
            position: relative;
            border: 1px solid #edeef1;
            background-color: #fff;
            border-radius: 25px;
            cursor: pointer;

            .folder_card_icon_wrapper {
                position: absolute;
                top: -30px;
                left: 30px;
                background-color: #6d81e1;
                padding: 12px;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            .folder_card_top_wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 12px 12px 0px 12px;
                height: 50px;


                .folder_card_top {
                    border: 2px solid #f0eff0;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    .eye-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }


            .folder_card_middle {
                padding: 35px 12px 20px 20px;

                h5 {
                    color: #0b3b74;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 6px;
                    width: 120px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    
                }

                .create_at {
                    color: #000;
                    font-size: 12px;
                    font-weight: 400;

                    span {
                        color: #000;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                p {
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 6px;

                    span {
                        color: #000;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }

            .folder_card_bottom {
                background-color: #e7ebfa;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 12px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                padding: 10px 12px;


                .file_repo_menu_icon {
                    .icon {
                        color: #5b5b5b;
                        font-size: 20px;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }


        }
    }

    .file_repository_folders_list_master {
        // display: grid;
        // grid-template-columns: repeat(7, 1fr);
        // grid-gap: 20px;
        // grid-row-gap: 60px;
        // margin-bottom: 30px;

        .folder-grids-file-repo {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 20px;
            grid-row-gap: 60px;
            // display: flex;
            // gap: 10px;
            // flex-wrap: wrap;
            margin-bottom: 30px;
        }


        .folder_card {
            position: relative;
            border: 1px solid #edeef1;
            background-color: #fff;
            border-radius: 25px;
            cursor: pointer;
            // width: 200px;

            .folder_card_icon_wrapper {
                position: absolute;
                top: -30px;
                left: 30px;
                background-color: #6d81e1;
                padding: 12px;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 500;
                }
            }

            .folder_card_top_wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 12px 12px 0px 12px;
                height: 50px;


                .folder_card_top {
                    border: 2px solid #f0eff0;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    .eye-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }


            .folder_card_middle {
                padding: 35px 12px 20px 20px;
                // height: 150px; 

                h5 {
                    color: #0b3b74;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 6px;
                    width: 120px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .create_at {
                    span {
                        color: #000;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }

                p {
                    color: #000;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 6px;

                    span {
                        color: #000;
                        font-size: 15px;
                        font-weight: 600;
                    }
                }
            }

            .folder_card_bottom {
                background-color: #e7ebfa;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 12px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 25px;
                padding: 10px 12px;


                .file_repo_menu_icon {
                    .icon {
                        color: #5b5b5b;
                        font-size: 20px;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }


        }
    }

    .file_repository_files_list {
        // display: grid;
        // grid-template-columns: repeat(5, 1fr);

        .file_card_list {
            padding: 12px 20px;
            border-radius: 6px;

            .file_list_top {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 50px;

                .file_card_icon_wrapper {
                    background-color: #f7f5fd;
                    padding: 12px;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        color: #322286;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }

                h5 {
                    color: #000;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0;

                }
            }

            .file_list_top_margin_zero {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 0px;

                .file_card_icon_wrapper {
                    background-color: #f7f5fd;
                    padding: 12px;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon {
                        color: #322286;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }

                h5 {
                    color: #000;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0;

                }
            }

            .files_list_wrapper {
                // display: grid;
                // grid-template-columns: repeat(5, 1fr);
                // gap: 15px;
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
            }



            .files_list {
                background-color: #fff;
                border: 1px solid #342197;
                padding: 20px 12px 10px;
                border-radius: 25px;
                cursor: pointer;
                height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;



                .file_main_list {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    margin-bottom: 20px;

                    .file_icon_wrapper {
                        .icon {
                            color: #1b84ff;
                            font-size: 26px;
                            font-weight: 500;
                        }
                    }

                    h6 {
                        text-overflow: ellipsis;
                        color: #0b3b74;
                        font-size: 18px;
                        font-weight: 500;
                        margin-bottom: 0;
                        text-transform: capitalize;
                        width: 250px;
                        white-space: normal;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .created_wrapper {
                    margin-bottom: 8px;

                    .create_at {
                        color: #000;
                        font-size: 12px;
                        font-weight: 400;

                        span {
                            color: #000;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }

                    .create_by {
                        color: #000;
                        font-size: 12px;
                        font-weight: 400;

                        span {
                            color: #000;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }


                }

                .file_card_list_bottom_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .file_card_bottom {
                        border: 2px solid #f0eff0;
                        border-radius: 50%;
                        width: 34px;
                        height: 34px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .eye-wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

            }

            .view-and-btn-wrapper {
                display: flex;
                justify-content: space-between;

                .document-footer {
                    .view-btn {
                        background-color: #FAFAFF;
                        color: #000000;
                        padding: 3px 8px;
                        border-radius: 200px;
                        border: 1px solid #E1E5E9;
                        cursor: pointer;
                        transition: 0.2s;
                        width: 85px;
                        height: 35px;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        justify-content: space-around;
                        font-weight: 500;
                        font-size: 14px;
                    }

                }
            }


        }
    }

    .file_one {
        background-color: #f1edfb;
    }
}