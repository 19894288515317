.star-team-container-wrapper {

    padding-top: 50px;
    display: flex;
    gap: 25px;
    align-items: center;
    flex-wrap: wrap;

    .star-team-child-cards {
        background-color: #fff;
        border-radius: 26px;
        box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
        height: 210px;
        position: relative;
        width: 230px;

        .star-team-profile {
            display: flex;
            justify-content: center;
            align-items: center;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            border-radius: 50px;
            height: 100px;
            object-fit: cover;
            width: 100px;
            background-color: #FFFFFF;
            border: 2px solid #CAC3DB;
        }

        .star-team-details {
            height: 200px;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 25px;
            padding-bottom: 20px;

            .star-team-name {
                font-size: 18px;
                font-weight: 500;
                color: #000000;
                padding-bottom: 6px;
            }

            .star-team-department {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
            }
        }

        .star-team-button {
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: #ffffff;
            color: #000000;
            border: 1px solid #EEEEEE;
            border-radius: 15px;
            font-size: 15px;
            font-weight: 500;
        }
    }
}