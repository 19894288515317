.assets-container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .assets-cards {
        border-radius: 30px;
        padding: 15px;
        min-height: 288px;
        max-height: 288px;
        width: 350px;
        flex-wrap: wrap;

        .assets-box-heading-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .assets-detail-wrapper {
                display: flex;
                align-items: center;
                gap: 25px;

                .assets-image-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;

                    &.mobile {
                        background-color: #FD3995;
                    }

                    &.camera {
                        background-color: #554AF5;
                    }

                    &.laptop {
                        background-color: #ffffff;

                    }
                }

                .asset-name-info {
                    .asset-heading {
                        font-weight: 400;
                        font-size: 16px;
                        color: #000;
                    }

                    .asset-status-value {
                        font-size: 16px;
                        font-weight: 300;
                        color: #000;

                    }

                }
            }

            .three-dot-icon-container {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 13px;
                cursor: pointer;
                position: relative;

                .assets-dropdown {
                    width: 170px;
                    z-index: 1;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    box-shadow: 0 3px 6px -10px rgba(0, 0, 0, 0.14);
                    background-color: white;
                    padding: 5px;

                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            padding: 8px 12px;
                            color: #5c586d;

                            .three-dot-dropdown-item {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 10px;
                                text-decoration: none;
                                font-weight: 400;
                                font-size: 14px;

                                .eye-wrapper {
                                    position: relative;
                                    background-color: #f5f7f9;
                                    width: 25px;
                                    height: 25px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 5px;
                                }
                            }

                            &:hover {
                                background-color: rgba(0, 0, 0, 0.14);
                                cursor: pointer;
                            }
                        }

                    }

                }
            }
        }
    }

    .asset-details-information {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 15px 0 15px;
        grid-column-gap: 10px;
        grid-row-gap: 20px;

        .asset-content {
            .asset-title {
                font-size: 16px;
                font-weight: 300;
                padding-bottom: 5px;
                color: #000;
            }

            .asset-value {
                font-weight: 400;
                font-size: 16px;
                color: #000;
                width: 90px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .asset-content:last-child {
            grid-column: span 2;
        }
    }
}