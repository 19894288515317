// .pad-left-right {
//     padding-left: 30px !important;
//     padding-right: 80px !important;
// }

.listing-wrapper-custom {
    border-radius: 12px;
    margin-bottom: 16px;
    border: 1px solid #E6E8E9;
}

.pos_sticky {
    position: sticky;
    top: 60px;
    z-index: 9;
}

.non_appraisal_wrapper_bg_blue_main {
    border-radius: 12px;
    background-image: linear-gradient(to right, #7b898d, #a38664);

    .non_appraisal_bg {
        padding: 16px 20px;
        background: url("../../images/selfAppraisal/self-appraisal-bg-1.png") 20% 80%, url("../../images/selfAppraisal/self-appraisal-bg-2.png")100% 100%, url("../../images/selfAppraisal/self-appraisal-star-single.png")26% 20%, url("../../images/selfAppraisal/self-appraisal-star-multiple.png")55% 50%, url("../../images/selfAppraisal/self-appraisal-star-single.png")75% 25%;
        background-repeat: no-repeat;
        background-size: auto, auto, 2.25rem, 5.25rem, 2.25rem;

        .non_appraisal_title {
            margin-bottom: 20px;

            h5 {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 0px;
            }

            p {
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 4px;
                max-width: 1300px;
            }
        }

        .inner-wrapper {
            display: flex;
            align-items: center;
            gap: 30px;

            .inner_wrapper_profile {
                display: flex;
                align-items: center;
                gap: 30px;
            }

            .pro_pic_shadow {
                background-color: #7070707d;
                width: 100px;
                height: 10px;
                border-radius: 50%;
                margin-top: 4px;
            }

            .profile_pic {
                width: 94px;
                height: 94px;
                object-fit: cover;
                border-radius: 50%;
                border: 4px solid #C2C8D5;
            }

            .details_wrapper {
                h3 {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 12px;
                }

                h5 {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                p {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0px;
                }
            }

        }

        .inner_wrapper_gap {
            gap: 50px !important;
        }
    }

    // .d_flex_main_wrapper_appraisal {
    //     display: flex;
    //     align-items: center;
    //     // justify-content: space-between;
    //     flex-wrap: wrap;
    // }

    .non_appraisal_bg_without {
        background: url("../../images/appraisal-mask.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 80%;
        padding: 16px 20px;


        .non_appraisal_title {
            // margin-bottom: 20px;
            background-color: #9e9582c9;
            padding: 18px 12px;
            border-radius: 12px;
            width: 160%;

            h5 {
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 6px;
            }

            p {
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0px;
                max-width: 1300px;

                .under_line {
                    color: #fff;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 0px;
                    text-decoration: underline;
                }
            }
        }

        .inner-wrapper {
            display: flex;
            align-items: center;
            width: 80%;
            gap: 40px;

            .inner_wrapper_profile {
                display: flex;
                align-items: center;
                gap: 30px;
                width: 350%;

                .emp_no {
                    font-size: 17px;
                }
            }

            .pro_pic_shadow {
                background-color: #7070707d;
                width: 80px;
                height: 8px;
                border-radius: 50%;
                margin-top: 4px;
            }

            .profile_pic {
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 50%;
                border: 4px solid #C2C8D5;
            }



            .details_wrapper {
                width: 100%;

                h3 {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 12px;
                }

                h5 {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 600;
                    margin-bottom: 6px;
                }

                h6 {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                p {
                    color: #fff;
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    margin-top: 4px;
                }
            }

        }

        .inner-wrapper-w-100 {
            width: 100% !important;
        }

        // .inner_wrapper_gap {
        //     gap: 50px !important;
        // }

    }

    @media(min-width: 768px) and (max-width: 992px) {
        .non_appraisal_bg_without {
            background: url("../../images/selfAppraisal/self-appraisal-bg-1.png") 20% 80%, url("../../images/selfAppraisal/self-appraisal-bg-2.png")100% 100%;
            background-repeat: no-repeat;
            background-size: auto, auto, 2.25rem, 5.25rem, 2.25rem;
        }
    }
}

.non_appraisal_wrapper_bg_blue {
    position: relative;
}

.non_appraisal_wrapper_bg_blue {
    border-radius: 12px;
    background-color: #0078ce;

    .non_appraisal_bg {
        padding: 16px 20px;
        background: url("../../images/selfAppraisal/self-appraisal-bg-1.png") 20% 80%, url("../../images/selfAppraisal/self-appraisal-bg-2.png")100% 100%, url("../../images/selfAppraisal/self-appraisal-star-single.png")26% 20%, url("../../images/selfAppraisal/self-appraisal-star-multiple.png")55% 50%, url("../../images/selfAppraisal/self-appraisal-star-single.png")75% 25%;
        background-repeat: no-repeat;
        background-size: auto, auto, 2.25rem, 5.25rem, 2.25rem;

        .non_appraisal_title {
            margin-bottom: 20px;

            h5 {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 0px;
            }

            p {
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 4px;
                max-width: 1300px;
            }
        }

        .inner-wrapper {
            display: flex;
            align-items: center;
            gap: 30px;

            .inner_wrapper_profile {
                display: flex;
                align-items: center;
                gap: 30px;
            }

            .pro_pic_shadow {
                background-color: #7070707d;
                width: 100px;
                height: 10px;
                border-radius: 50%;
                margin-top: 4px;
            }

            .profile_pic {
                width: 94px;
                height: 94px;
                object-fit: cover;
                border-radius: 50%;
                border: 4px solid #C2C8D5;
            }

            .details_wrapper {
                h3 {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 12px;
                }

                h5 {
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                p {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 0px;
                }
            }

        }

        .inner_wrapper_gap {
            gap: 50px !important;
        }
    }

    // .d_flex_main_wrapper_appraisal {
    //     display: flex;
    //     align-items: center;
    //     // justify-content: space-between;
    //     flex-wrap: wrap;
    // }

    .non_appraisal_bg_without {
        background: url("../../images/appraisal-mask.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 80%;
        padding: 16px 20px;


        .non_appraisal_title {
            // margin-bottom: 20px;
            background-color: #9e9582c9;
            padding: 18px 12px;
            border-radius: 12px;
            width: 160%;

            h5 {
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 6px;
            }

            p {
                color: #fff;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 0px;
                max-width: 1300px;

                .under_line {
                    color: #fff;
                    font-size: 15px;
                    font-weight: 500;
                    margin-bottom: 0px;
                    text-decoration: underline;
                }
            }
        }

        .inner-wrapper {
            display: flex;
            align-items: center;
            width: 80%;
            gap: 40px;

            .inner_wrapper_profile {
                display: flex;
                align-items: center;
                gap: 30px;
                width: 350%;
            }

            .pro_pic_shadow {
                background-color: #7070707d;
                width: 80px;
                height: 8px;
                border-radius: 50%;
                margin-top: 4px;
            }

            .profile_pic {
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 50%;
                border: 4px solid #C2C8D5;
            }



            .details_wrapper {
                width: 100%;

                h3 {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 12px;
                }

                h5 {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                h6 {
                    color: #fff;
                    font-size: 13px;
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                p {
                    color: #fff;
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    margin-top: 4px;
                }
            }

        }

        // .inner_wrapper_gap {
        //     gap: 50px !important;
        // }

    }

    @media(min-width: 768px) and (max-width: 992px) {
        .non_appraisal_bg_without {
            background: url("../../images/selfAppraisal/self-appraisal-bg-1.png") 20% 80%, url("../../images/selfAppraisal/self-appraisal-bg-2.png")100% 100%;
            background-repeat: no-repeat;
            background-size: auto, auto, 2.25rem, 5.25rem, 2.25rem;
        }
    }
}

.non_appraisal_mask_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92px;
    background: url("../../images/selfAppraisal/masksvg.png")50% 50%;
    background-repeat: no-repeat;
    background-size: 980px;
    margin-top: -1px;

    .nav-pills {
        // margin-bottom: 12px;

        .nav-item {
            position: relative;

            .arrow_wrapper {
                position: absolute;
                top: 46px;
                right: 16px;
            }

            .nav-link {
                background-color: transparent;
                padding: 12px 26px;
                border-radius: 25px;
                color: #fff;
                font-size: 14px;

                &.active {
                    background-color: #fff;
                    color: #000;
                    font-size: 14px;

                }
            }
        }
    }

}

@media (min-width: 768px) and (max-width: 992px) {
    .non_appraisal_mask_wrapper {
        border-radius: 12px;
        background-color: #0078CE;
        margin-top: 5px;
    }
}

.navpills_content_wrapper {
    padding: 20px 0px;
    position: relative;

    .decor_wrapper {
        position: absolute;
        top: -44px;
        right: -64px;
    }

    .decor_wrapper_appraisal {
        position: absolute;
        top: 24px;
        right: -64px;
    }

    .navpills_content_bg_decor {
        .content_main_wrapper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 5px;
            padding: 24px 40px;
            border-radius: 12px;
            margin-bottom: 16px;
            // max-height: 125px;
            // min-height: 125px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

            &:hover {
                transform: scale(1.02);
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                opacity: 0.9;
            }

            .content-sub-wrapper {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
                align-items: center;
            }

            .published-tag {
                position: absolute;
                top: 0px;
                right: 0px;
                background-color: #013979ba;
                color: white;
                padding: 5px 10px;
                font-size: 12px;
                font-weight: bold;
                border-top-right-radius: 10px;

            }
        }

        .left_wrapper {
            h5 {
                color: #000;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 12px;
            }

            p {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 4px;
                max-width: 1300px;

                ul {
                    padding-left: 36px;

                    li {
                        font-weight: 400;
                    }
                }
            }

            .lock_details {
                display: flex;
                align-items: center;

                .icon_wrapper {
                    .icon {
                        color: #e70606;
                        font-size: 18px;
                        margin-right: 4px;
                        margin-bottom: 0;
                    }
                }

                .content_details {
                    color: #e70606;
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-top: 2px;
                }
            }

            .warning_msg_appraisal {
                display: flex;
                align-items: center;
                gap: 4px;
                color: #F94747;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 4px;
            }
        }

        .middle-wrapper {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .sub-headings {
                color: #141313;
                font-size: 14px;
                font-weight: 500;
            }

            .results {
                font-size: 15px;
                font-weight: 600;
                color: #1c1c1c;
            }

            .score-wrapper {
                width: 460px;
                align-items: center;
                border: 1px solid #aba3a38f;
                border-radius: 12px;
                display: flex;
                /* justify-content: center; */
                padding: 16px 12px;

                .score-content {
                    width: 130px;
                }

                .rating-content {
                    .rating_wrapper_questions {
                        .apprisal_rating_label {
                            color: #1c1c1c;
                            font-size: 17px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .appraisal-info-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid #aba3a38f;
            border-radius: 12px;
            gap: 15px;
            padding: 16px 12px;
            width: 460px;
        }
    }
}

.right_wrapper_button_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.right_wrapper {



    p {
        color: #0BA700;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    span {
        color: #000;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .btn-groups-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;

        .save-btn {
            background-color: #013979;
            border-color: #013979;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            gap: 6px;
            border-radius: 12px;
        }
    }
}


.content_main_wrapper_goals {

    .title_wrapper {
        padding: 16px 30px;

        h5 {
            color: #000;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 4px;
        }
    }

    .line_wrapper {
        border-bottom: 2px dashed #E6E8E9;
    }

    .d_flex_wrapper_appraisal {
        padding: 12px 40px;

        h5 {
            font-size: 17px;
            font-weight: 700;
            color: #000;
            margin-bottom: 12px;

            i {
                color: #013979;

            }
        }

        .input-border-appraisal {
            position: relative;

            &::before {
                position: absolute;
                content: "";
                height: 40px;
                width: 3px;
                background-color: #698ab0;
                left: 0;
                top: 0;
            }
        }

        .left_wrapper {


            p {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 4px;
                max-width: 1300px;
            }

            .warning_msg_appraisal {
                display: flex;
                align-items: center;
                gap: 4px;
                color: #F94747;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 4px;
            }

            .form-check-input {
                &:checked {
                    background-color: #1f3a6e;
                    border-color: #1f3a6e !important;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .form-check-label {
                font-size: 14px;
                font-weight: 500;
                color: #000;
            }
        }

        .right_wrapper {
            background-color: #fff;
            // box-shadow: 0 0 20px rgba(1, 32, 90, 0.06);
            border-radius: 12px;
            margin-bottom: 16px;
            padding: 10px 20px;
            border: 1px solid #E6E8E9;

            .add-more-wrapper-appraisal {
                .add_more {
                    color: #013979;
                    font-size: 14px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    // cursor: pointer;
                    margin-bottom: 15px;

                    img {
                        width: 15px;
                        margin-top: -4px;
                    }

                    span {
                        cursor: pointer;
                        margin-bottom: 0px;
                    }
                }
            }

            .delete-sec-wrapper {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .delete-sec-appraisal {
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    gap: 10px;
                    background-color: #fff;
                    padding: 2px 7px;
                    border-radius: 5px;
                    cursor: pointer;
                    width: fit-content;
                    margin-top: 12px;

                    span {
                        margin-bottom: 0px;
                    }

                }
            }

            p {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 4px;
            }

            span {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 4px;
            }

            .dotted-input {
                border: 1px solid #d2d4da;
                border-radius: 0 !important;
                position: relative;
                margin-left: 0px;
                border-radius: 0px;
                font-size: 14px;
                min-height: 20px;
                color: #696a72;
                margin-bottom: 20px;


            }

            .area-height {
                height: 120px;
            }

            .score-input-box {
                border-radius: 30px !important;
                aspect-ratio: 4/1;
                max-width: 45px;
                text-align: center;
                background-color: #013979;
                color: white;
                margin-bottom: 9px;
            }

            .check_box_input {
                display: flex;
                gap: 8px;
                align-items: center;
                margin-bottom: 20px;

                .form-check-input {
                    border: 1px solid #d2d4da;
                    border-radius: 0;
                    width: 30px;
                    height: 30px;

                    &:focus {
                        box-shadow: none;
                    }

                    &:checked {
                        background-color: #013979;
                        border-color: #013979;
                    }
                }

            }

            .w-10 {
                width: 10%;
            }

            .w-20 {
                width: 30%;
            }

            .w-40 {
                width: 40%;
            }

            .btn-groups-wrapper {
                display: flex;
                align-items: center;
                gap: 12px;

                .save-btn {
                    background-color: #013979;
                    border-color: #013979;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 500;
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    border-radius: 12px;
                }
            }
        }
    }

    .listing_custom_input {

        h5 {
            font-size: 14px;
            font-weight: 600;
            color: #575757;
            margin-bottom: 0px;
        }

        .form-label {
            font-size: 14px;
            font-weight: 700;
            color: #000;
        }

        .input-border {
            position: relative;

            &::before {
                position: absolute;
                content: "";
                height: 40px;
                width: 3px;
                background-color: #698ab0;
                left: 0;
                top: 0;
            }

            .dotted-input-half {
                border: 1px dashed #d2d4da;
                border-radius: 0;
                position: relative;
                border-left-color: #fff;
                margin-left: 5px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                font-size: 14px;
                color: hsl(0, 0%, 20%);
            }

            .disabled-input {
                background-color: #f0f0f0;
                color: #013979;
                font-weight: 600;
            }
        }
    }


}

.margin_top_12 {
    margin-top: 12px;
}

.no_data_found_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 24px 40px;
    border-radius: 12px;
    margin-bottom: 16px;
    border: 1px solid #E6E8E9;

    h5 {
        color: #585868;
        font-size: 16px;
        font-weight: 500;
    }
}

.border_left_first {
    position: relative;


    &::before {
        position: absolute;
        content: "";
        height: 110px;
        width: 3px;
        background-color: #BBDBF1;
        left: 0px;
        top: 6px;
    }
}

.border_left_second {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 110px;
        width: 3px;
        background-color: #DEF6D5;
        left: 0px;
        top: 6px;
    }
}

.border_left_third {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 110px;
        width: 3px;
        background-color: #D4D4EF;
        left: 0px;
        top: 6px;
    }
}

.border_left_fourth {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 110px;
        width: 3px;
        background-color: #CDE8FB;
        left: 0px;
        top: 6px;
    }
}

.border_left_fifth {
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 110px;
        width: 3px;
        background-color: #FFD5DF;
        left: 0px;
        top: 6px;
    }
}


.bounce2 {
    animation: bounce2 3s ease infinite;
}

@keyframes bounce2 {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.v-line {
    position: relative;

    &::after {
        background-color: rgba(255, 255, 255, 0.4392156863);
        content: "";
        height: 50px;
        position: absolute;
        right: 40px;
        width: 2px;
        top: 0px;
    }
}

.v-line-score {
    position: relative;

    &::after {
        background-color: #e3e5ea;
        content: "";
        height: 70px;
        position: absolute;
        right: -20px;
        width: 2px;
        top: -8px;
    }
}


.team_appraisal_wrapper {
    .card-paddding {
        .card_wrapper {
            border: none;
            border-radius: 5px;
            box-shadow: 0 0 12px -1px rgba(0, 0, 0, .11);
            height: 100%;

            .card-body {
                padding: 0px 0px;

                .team-appraisal-wrapper-card {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .appraisal-details-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }

                    .appraisal-status {
                        h6 {
                            background-color: #fff;
                            color: black;
                            padding: 8px 16px;
                            border-radius: 19px;
                            font-size: 14px;
                            width: 120px;
                            text-align: center;
                        }
                    }
                }

                .details_wrapper {
                    h3 {
                        color: #fff;
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 12px;
                    }

                    h5 {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 700;
                        margin-bottom: 0px;
                    }

                    p {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        margin-top: 4px;
                    }
                }

                .img-wrapper {
                    .pro_pic_shadow {
                        background-color: #7070707d;
                        width: 100px;
                        height: 10px;
                        border-radius: 50%;
                        margin-top: 4px;
                    }

                    .profile_pic {
                        width: 94px;
                        height: 94px;
                        object-fit: cover;
                        border-radius: 50%;
                        border: 4px solid #C2C8D5;

                        @media(max-width:767px) {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }

                .card-title {
                    color: #000;
                    font-size: 30px;
                    font-weight: 500;
                }

                .card-text {
                    color: #000;
                    font-size: 18px;
                    font-weight: 500;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    // min-height: 50px;
                    margin-bottom: 12px;
                }

                .blog-details {
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    font-size: 14px;
                    margin-bottom: 15px;
                    text-align: justify;
                    color: #000;
                }

                .card-date {
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 15%;
                }

                .card-link {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;

                    .arrow_right {
                        width: 22px;
                        height: 12px;
                    }

                    .arrow-9 {
                        animation: a9 0.9s infinite linear alternate;
                    }

                    @keyframes a9 {
                        0% {
                            transform: translate(-4px);
                        }

                        100% {
                            transform: translate(4px);
                        }
                    }
                }
            }

            &:hover {
                background-color: transparent;
            }
        }

    }

    .non_appraisal_wrapper {
        position: relative;
        border-radius: 12px;
        background-color: #0078ce;

        .featured-logo {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 3px 12px 3px 10px;
            height: 20px;
            color: #fff;
            font-weight: 400;
            line-height: 13px;
            font-style: italic;
            font-size: 14px;
            letter-spacing: .5px;
            background-color: #fbb616cf;
            border-radius: 0 10px 0px 0;
        }

        .featured-logo-striked {
            position: absolute;
            top: 0px;
            right: 0px;
            padding: 3px 12px 3px 10px;
            height: 20px;
            color: #fff;
            font-weight: 400;
            line-height: 13px;
            font-style: italic;
            font-size: 14px;
            letter-spacing: .5px;
            background-color: #9c7a2acf;
            border-radius: 0 10px 0px 0;
            text-decoration: line-through;
        }

        .non_appraisal_bg_team {
            padding: 16px 20px;
            background: url("../../images/selfAppraisal/self-appraisal-bg-1.png") 20% 80%, url("../../images/selfAppraisal/self-appraisal-bg-2.png")100% 100%;
            background-repeat: no-repeat;
            background-size: auto, auto, 5.25rem, 2.25rem;

            .non_appraisal_title {
                margin-bottom: 20px;

                h5 {
                    color: #fff;
                    font-size: 20px;
                    font-weight: 500;
                    margin-bottom: 0px;
                }

                p {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    max-width: 1300px;
                }
            }

        }

        @media(min-width: 768px) and (max-width: 992px) {
            .non_appraisal_bg_team {
                background: url("../../images/selfAppraisal/self-appraisal-bg-2.png")100% 100%, url("../../images/selfAppraisal/self-appraisal-star-multiple.png")96% 8%, url("../../images/selfAppraisal/self-appraisal-star-single.png")65% 10%;
                background-repeat: no-repeat;
                background-size: auto, auto, 5.25rem, 2.25rem;
            }
        }
    }

    .no_data_found_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 24px 40px;
        border-radius: 12px;
        margin-bottom: 16px;
        border: 1px solid #E6E8E9;

        h5 {
            color: #585868;
            font-size: 16px;
            font-weight: 500;
        }
    }

    @media (max-width: 968px) {}

    @media (max-width: 768px) {}

    @media (max-width: 568px) {}

    @media (max-width: 468px) {}


}

.result_wrapper_appraisal {

    .card-padding-team {
        .card_wrapper {
            border: none;
            border-radius: 5px;
            // box-shadow: 0 0 12px -1px rgba(0, 0, 0, .11);
            height: 100%;

            .card-body {
                padding: 0px 0px;

                .details_wrapper {
                    margin-bottom: 12px;

                    h3 {
                        color: #fff;
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 4px;
                    }

                    h5 {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 0px;
                    }

                    p {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 0px;
                        margin-top: 4px;
                    }
                }

                .img-wrapper {
                    .pro_pic_shadow {
                        background-color: #7070707d;
                        width: 100px;
                        height: 10px;
                        border-radius: 50%;
                        margin-top: 4px;
                    }

                    .profile_pic {
                        width: 94px;
                        height: 94px;
                        object-fit: cover;
                        border-radius: 50%;
                        border: 4px solid #C2C8D5;

                        @media(max-width:767px) {
                            width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }

                .card-title {
                    color: #000;
                    font-size: 30px;
                    font-weight: 500;
                }

                .card-text {
                    color: #000;
                    font-size: 18px;
                    font-weight: 500;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    // min-height: 50px;
                    margin-bottom: 12px;
                }

                .blog-details {
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    font-size: 14px;
                    margin-bottom: 15px;
                    text-align: justify;
                    color: #000;
                }

                .card-date {
                    color: #000;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 15%;
                }

                .card-link {
                    color: #000;
                    font-size: 14px;
                    font-weight: 400;

                    .arrow_right {
                        width: 22px;
                        height: 12px;
                    }

                    .arrow-9 {
                        animation: a9 0.9s infinite linear alternate;
                    }

                    @keyframes a9 {
                        0% {
                            transform: translate(-4px);
                        }

                        100% {
                            transform: translate(4px);
                        }
                    }
                }
            }

            &:hover {
                background-color: transparent;
            }
        }

    }

    .right_wrapper_bg {
        background-color: #fff !important;

        .previous_result_details {
            display: flex;
            align-items: center;
            gap: 12px;

            .input-border {
                .dotted-input-team {
                    border: 1px dashed #d2d4da;
                    border-radius: 0 !important;
                    margin-left: 0px;
                    border-radius: 0px;
                    font-size: 14px;
                    min-height: 40px;
                    color: #000;
                    margin-bottom: 20px;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .previous_result_comments {

            .input-border {
                .dotted-input-team {
                    border: 1px dashed #d2d4da;
                    border-radius: 0 !important;
                    margin-left: 0px;
                    border-radius: 0px;
                    font-size: 14px;
                    min-height: 40px;
                    color: #000;
                    margin-bottom: 20px;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }

    }

    .non_appraisal_bg_team_appraisal {
        padding: 16px 20px !important;
        cursor: text;

        .non_appraisal_title {
            margin-bottom: 20px;

            h5 {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 0px;
            }

            p {
                color: #fff !important;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 4px;
                max-width: 1300px;
            }
        }
    }
}

.d_flex_condition {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.reviewer_details_wrapper_team_bg {
    border: 1px solid #828f90;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    .reviewer_details_wrapper_team {
        display: flex;
        justify-content: space-between;

        .reviewer_padding_details {
            padding: 6px 12px;
        }

        h4 {
            font-size: 16px;
            font-weight: 700;
            color: #000;
            margin-bottom: 4px;
        }

        .reviewer_details_score {
            background-image: linear-gradient(to bottom, #7b898d, #a38664);
            padding: 12px 12px;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;


            h5 {
                color: #fff !important;
                font-size: 14px !important;
                font-weight: 700 !important;
            }
        }
    }
}

.share_feedback_wrapper {
    width: 650px !important;

    .offcanvas-body {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .offcanvas-header {
        .btn-close {
            &:focus {
                box-shadow: none;
            }
        }
    }

    .inner-wrapper {

        .inner_wrapper_profile {
            display: flex;
            gap: 16px;
            margin-bottom: 30px;

        }

        // .pro_pic_shadow {
        //     background-color: #7070707d;
        //     width: 100px;
        //     height: 10px;
        //     border-radius: 50%;
        //     margin-top: 4px;
        // }

        .profile_pic {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid #C2C8D5;
        }

        .details_wrapper {
            margin-bottom: 20px;

            .feedback_name {
                color: #013979;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 4px;
            }

            .text_shared {
                color: #000;
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 4px;
            }

            h5 {
                color: #766f6f;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 0px;
            }

            p {
                color: #000;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 0px;
            }

            .comment_details_wrapper {
                margin-top: 20px;

                .comment_rating {
                    color: #000;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                .comment_data {
                    color: #4e4c4c;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }
            }
        }

        .comment_send_section {
            padding: 40px 60px;
            background: #efefef;
        }

    }

    .inner_wrapper_gap {
        gap: 50px !important;
    }
}

.share_feedback_button {
    background-color: #fff !important;
    color: #000 !important;
    padding: 6px 6px !important;
    font-size: 12px !important;
    border-radius: 12px !important;
    text-align: center;
}

.primary-bg {
    background-color: #fff;
    border-left: 6px solid #ff00005e;
    border-top: 2px solid #ff00005e;
    border-bottom: 2px solid #ff00005e;
    border-right: 2px solid #ff00005e;
}

.secondary-bg {
    background-color: #fff;
    border-left: 6px solid #0a60f678;
    border-top: 2px solid #0a60f678;
    border-bottom: 2px solid #0a60f678;
    border-right: 2px solid #0a60f678;
}

.ternary-bg {
    background-color: #fff;
    border-left: 6px solid #eba13566;
    border-top: 2px solid #eba13566;
    border-bottom: 2px solid #eba13566;
    border-right: 2px solid #eba13566;
}

.previous_result_goals {
    h5 {
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-bottom: 10px;
    }

    h6 {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        margin-bottom: 4px;
    }

    .goal_body {
        font-size: 12px;
        font-weight: 600;
        color: #000;
        margin-bottom: 4px;
    }
}

.goal-item {
    ul {
        padding-left: 32px;
    }
}

.details_wrapper_border {
    border: 1px solid #fff;
    padding: 16px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: 340%;
}

.questions_wrapper {
    .question_title {
        background-color: #e6e6e6;
        padding: 12px;
        border-radius: 12px;

        h5 {
            color: #000;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
            text-align: center;
        }
    }

    .salary_designation_revision {
        background-color: #eeeadc;
        padding: 12px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        .salary_designation_revision_flex_wrapper {
            display: flex;
            align-items: center;
            gap: 80px;
        }

        h5 {
            color: #000;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
            text-align: center;
        }

        .check_box_input {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-bottom: 0px;

            .form-check-input {
                border: 1px solid #d2d4da;

                &:focus {
                    box-shadow: none;
                }

                &:checked {
                    background-color: #013979;
                    border-color: #013979;
                }
            }

        }
    }
}

.d_question_flex_wrapper {
    display: flex;
    justify-content: space-between;

    .left_wrapper {
        width: 50%;
    }

    .right_wrapper {
        width: 50%;
    }
}

.rating_wrapper_questions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 4px;

    .rating_label {
        color: #fff;
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.scroll_max_height {
    // max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Scrollbar styles for WebKit (Chrome, Safari, Edge) */
// .scroll_max_height::-webkit-scrollbar {
//     width: 10px;
//     /* Scrollbar width */
//     height: 10px;
//     /* Horizontal scrollbar height */
// }

// .scroll_max_height::-webkit-scrollbar-track {
//     background: #f1f1f1;
//     /* Track background */
//     border-radius: 5px;
// }

// .scroll_max_height::-webkit-scrollbar-thumb {
//     background: #3a5d85;
//     /* Scrollbar color */
//     border-radius: 5px;
// }

// .scroll_max_height::-webkit-scrollbar-thumb:hover {
//     background: #2c4a6e;
//     /* Darker color on hover */
// }

// /* Scrollbar styles for Firefox */
// .scroll_max_height {
//     scrollbar-color: #3a5d85 #f1f1f1;
//     /* Thumb color & track color */
//     scrollbar-width: thin;
//     /* Can be auto, thin, or none */
// }

.transition-div {
    transition: all 0.5s ease-in-out;
    opacity: 1;
}

.transition-div-enter {
    opacity: 0;
    transform: translateY(-10px);
}

.transition-div-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s ease-in-out;
}

.change_in_designation {
    border: 1px solid #E6E8E9;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 12px;

    .dotted-input-custom {
        border: 1px solid #d2d4da;
        border-radius: 0;
        font-size: 14px;
        min-height: 40px;
        color: #696a72;
        border-radius: 6px;

        &:focus {
            box-shadow: none;
        }
    }


}

.dotted-select-custom {
    .css-1im77uy-control {
        min-height: 40px;
        border: 1px solid #d2d4da;
        border-radius: 6px;
        text-transform: capitalize;
    }
}

.date-picker-group-custom {
    .MuiStack-root {
        background-color: #fff;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #d2d4da !important;
        border-radius: 6px !important;
    }
}

.details_wrapper_feedback {
    border: 1px solid #fff;
    padding: 16px 12px;
    border-radius: 12px;
    margin-top: 12px;

    h5 {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 4px;
    }

    p {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
    }
}

.d_flex_non_appraisal_title {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    .appraisal-status-feedback {
        h6 {
            background-color: #fff;
            color: black;
            padding: 8px 16px;
            border-radius: 19px;
            font-size: 14px;
            width: 120px;
            text-align: center;
        }
    }
}

.warning_icon_info {
    .icon {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        margin-left: 20px;
    }
}


.border-line {
    position: relative;

    &::after {
        background-color: #aba3a38f;
        content: "";
        height: 50px;
        position: absolute;
        right: 40px;
        width: 2px;
        top: 0px;
    }
}