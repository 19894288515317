/*=================
Global css start
===================*/

$welcome-dashboard-primary-color: #FAB415;
$welcome-heading-color: #FAB415;

.employee-dashboard {
    background: linear-gradient(45deg, #FCF9FF, #ffffff);
}

.welcome-dashboard-bg {
    background-color: hsla(0, 0%, 100%, 1);
    background-image: radial-gradient(at 100% 100%, hsla(0, 0%, 100%, 1) 0px, transparent 50%), radial-gradient(at 38% 70%, hsla(289, 80%, 88%, 0.32) 0px, transparent 50%);
}

.divide-line {
    border: 1px solid #EFF0F1;
    margin: 10px 0;
}

.dashboard-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 23px;
}

.sub-title {
    color: #013978;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-right: 10px;
}

.view-more {
    color: #013978;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: inline-block;
}

.calendar-btn-groups {
    margin-top: 20px;
}

.calendar-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #013979 !important;
    border-color: #013979 !important;
    background-color: #fff !important;
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 5px !important;
    min-width: 110px !important;
    font-weight: 500;
    font-size: 14px;

    img {
        width: 18px;
        height: 18px;
    }
}


.small-icon {
    background-color: #f5f7f9;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 13px;
}

.employee-dashboard-title {
    margin: 20px 0;
}

.card-required-margin {
    margin-top: 20px;
}

.card_height_cust {
    max-height: 380px;
    min-height: 380px;
}

.employee-dashboard-card {
    background-color: #FFFFFF;
    border: 2px solid #EFF0F1;
    padding: 10px;
    border-radius: 4px;

    .dashboard-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 5px;
    }

    .attendance-chart {
        .loader {
            text-align: center;

            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.main-content-wrapper {
    padding: 0 12px;
}

.welcome-dashboard-no-data-message {
    border-bottom: 2px solid #EFF0F1;
    border-right: 2px solid #EFF0F1;
    border-left: 2px solid #EFF0F1;
    flex-grow: 1;
}

.welcome-dashboard-card {
    .welcome-dashboard-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px;
        background: rgb(15, 93, 176);
        background: radial-gradient(circle, rgba(15, 93, 176, 1) 0%, rgba(4, 25, 72, 1) 90%);
        border: 2px solid $welcome-dashboard-primary-color;
    }
}

.welcome-dashboard-title {
    color: $welcome-dashboard-primary-color;
    font-size: 16px;
    font-weight: 800;
}

.welcome-dashboard-view-more {
    color: #ffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: inline-block;
}

/*===============
Global css End
================*/

/*===================
Date Picker css start
=====================*/

.employee-date-picker {
    .dotted-calender {
        .MuiStack-root {
            padding-top: 0 !important;
            background-color: #fff;

            &:hover {
                border-color: transparent;
                border: none !important;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
            border: 1px dashed #d2d4da !important;

        }

        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
            border: 1px dashed #d2d4da !important;
        }
    }
}


.employee-information-box {
    display: flex;
    align-items: baseline;
    gap: 10px;

    .employee-information-content {
        .employee-information-title {
            color: #000;
            font-weight: 300;
            font-size: 14px;
            padding-bottom: 8px;
            display: flex;
            gap: 1px;

        }

        .employee-information-value {
            color: #000;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

/*================================
Employee profile section css start
==================================*/

.employee-profile-section {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #EFF0F1;
    border-radius: 4px;
    padding: 15px;
    background-image: url(../../../assets/images/employeedashboard/bg-1.png), url(../../../assets/images/employeedashboard/dash-board-icons/bg-3.png);
    background-position: left center, right center;
    background-repeat: no-repeat, no-repeat;
    background-size: auto, auto;
    display: flex;
    gap: 40px;
    position: relative;
    margin-top: 10px;

    .employee-profile-card {
        display: flex;
        align-items: center;
        gap: 30px;

        .employee-profile-img-wrapper {
            position: relative;

            img {
                width: 120px;
                height: 120px;
                object-fit: cover;
                object-position: top;
                border: 4px solid #F8F3FD;
                pointer-events: none;
            }

            .status-point {
                // background-color: #48BA6F;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                border: 2px solid #F8F3FD;
                position: absolute;
                right: 4px;
                bottom: 16px;
            }

        }

        .employee-profile-wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .employee-details-section-1 {
                display: flex;
                gap: 30px;

                .employee-information {
                    background-color: #DEE5EE;
                    border-radius: 14px;
                    padding: 10px 20px;
                    width: 320px;

                    h1 {
                        color: #000;
                        font-size: 24px;
                        font-weight: 600;
                        margin-bottom: 6px;
                    }

                    h4 {
                        color: #000;
                        font-size: 20px;
                        font-weight: 500;
                    }
                }

                .status {
                    h6 {
                        color: white;
                        padding: 8px 16px;
                        border-radius: 19px;
                        font-size: 14px;
                        width: 120px;
                        text-align: center;
                    }
                }
            }

            @media(min-width: 768px) and (max-width: 992px) {
                .employee-details-section-1 {
                    gap: 15px;
                }
            }
        }

        .employee-details-section-2 {
            display: flex;
            margin-top: 10px;
            justify-content: space-between;

            .detail-title {
                font-size: 16px;
                font-weight: 300;
                padding-bottom: 10px;
                color: #000;
            }

            .detail-value {
                font-weight: 400;
                font-size: 16px;
                color: #000;
            }
        }


        .dash-box {
            border-right: 2px dashed #848484;
            margin-left: 40px;
            margin-right: 40px;
            height: 150px;
            opacity: 0.2;
        }

        @media (max-width: 1440px) {
            .dash-box {
                display: none;
            }
        }
    }

    @media(min-width: 768px) and (max-width: 992px) {
        .employee-profile-card {
            gap: 15px;
        }
    }

    @media (min-width: 1290px) and (max-width: 1359px) {
        .employee-profile-card {
            gap: 15px;
        }
    }

    .employee-personal-information-card {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 36px;
        grid-row-gap: 30px;



        .service-book-btn {
            color: #FFFFFF;
            background-color: #013979;
            border-color: #013979;
            padding: 5px 10px;
            font-size: 14px;

            &:focus-visible {
                box-shadow: none;
            }
        }

    }


    @media (min-width: 1290px) and (max-width: 1359px) {
        .employee-personal-information-card {
            grid-column-gap: 10px;
        }
    }

    @media (min-width: 768px) and (max-width: 818px) {
        .employee-personal-information-card {
            grid-column-gap: 18px;
        }
    }


    .profile-basic {
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .vertical-timeline-element-content {
        box-shadow: 0 8px 30px #d6d6d6c7 !important;
        padding: 14px !important;

        .vertical-timeline-element-title {
            color: #000;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 6px;
        }
    }
}

@media (min-width: 1290px) and (max-width: 1359px) {
    .employee-profile-section {
        gap: 15px;
    }
}

@media (max-width : 1289px) {
    .employee-profile-section {
        flex-wrap: wrap;
    }
}

.employee-dashboard-card-section {

    .appraisals-card {

        .appraisals-card-info {
            display: flex;
            justify-content: space-between;
            align-items: center
        }

        .appriasals-details-wrapper {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .appriasals-box {
                background-color: #FFFFFF;
                display: flex;
                justify-content: space-around;
                align-items: center;
                border-radius: 10px;
                border: 2px dashed #EFF0F1;
                padding: 10px;

                .appraisals-contents {
                    .appriasals-title {
                        color: #000;
                        font-weight: 300;
                        font-size: 16px;
                        padding-bottom: 8px;
                    }

                    .appriasals-value {
                        color: #000;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    /*========================
    To-do card css Start
    ==========================*/
    .to-do-list-card {
        .row-container {
            overflow-y: auto;
            height: 335px;

            .to-to-list-card {
                border: 1px solid #EFF0F1;
                border-radius: 10px;
                padding: 15px;
                margin: 6px;
                height: 120px;

                .task-name {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 60px;
                    line-height: 1.5em;
                    word-break: break-word;
                }

                .to-do-detail-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .mark-as-read {
                        border: 2px solid #FFFFFF;
                        border-radius: 10px;
                        padding: 4px 10px;
                        color: #000;
                        font-size: 13px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    /*========================
    To-do card css End
    ==========================*/
}

@media(min-width: 992px) {
    .performance-wrapper {
        display: flex;
        gap: 8px;
    }
}

.performance-yearly-card {
    .performance-yearly-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
}

.documents-card {
    padding: 15px;

    .documents-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .document-box {
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
        border: 1px solid #EFF0F1;
        margin: 6px 6px;
        height: 130px;

        .document-wrapper {
            display: flex;
            justify-content: space-between;
        }

        .document-title {
            font-weight: 400;
            font-size: 16px;
            color: #000;
            padding: 8px 0;
        }

        .document-value {
            font-size: 16px;
            font-weight: 300;
            padding-bottom: 10px;
            color: #000;
        }
    }
}

.asset-card {
    .asset-card-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .assets-box {
        background-color: #FFFFFF;
        border: 1px solid #EFF0F1;
        border-radius: 6px;
        padding: 15px;
        margin: 6px 10px;
        min-height: 280px;
        max-height: 280px;

        .assets-box-heading-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .assets-detail-wrapper {
                display: flex;
                align-items: center;
                gap: 25px;

                .assets-image-box {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }

                .asset-name-info {
                    .asset-heading {
                        font-weight: 400;
                        font-size: 16px;
                        color: #000;
                    }

                    .asset-status-value {
                        font-size: 16px;
                        font-weight: 300;
                        color: #000;

                        .working-status {
                            font-weight: 400;
                            font-size: 16px;
                            color: #0D9A0D;
                        }
                    }

                }




            }
        }

        .asset-details-information {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            padding: 15px 0 15px;
            grid-column-gap: 10px;
            grid-row-gap: 20px;

            .asset-content {
                .asset-title {
                    font-size: 16px;
                    font-weight: 300;
                    padding-bottom: 5px;
                    color: #000;
                }

                .asset-value {
                    font-weight: 400;
                    font-size: 16px;
                    color: #000;
                }
            }
        }
    }
}

.signed-doc-card {
    height: 280px;

    .signed-doc-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .signed-doc-wrapper {
        .signed-doc-box {
            margin: 6px;
            display: flex;
            gap: 20px;
            background-color: #FFFFFF;
            border: 2px solid #EFF0F1;
            border-radius: 10px;
            padding: 15px;
            // width: 50%;

            .signed-doc-detail-info {
                .signed-doc-title {
                    padding-bottom: 8px;
                    font-weight: 400;
                    font-size: 16px;
                    color: #000;
                }

                .signed-doc-value {
                    font-size: 16px;
                    font-weight: 300;
                    color: #000;
                }

                .document-open-link {
                    text-decoration: none;
                    color: #013979;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}

.ticket-card {

    max-height: 570px;
    min-height: 570px;

    .ticket-tab-bg {
        border: 1px solid #c9c3c3;
        max-width: fit-content;
        border-radius: 50px;
    }

    .nav-pills .nav-link {
        color: #000;
        font-size: 14px;
        font-weight: 600;


        &.active {
            background-color: #013979;
            color: #fff;
            border-radius: 50px;
            font-size: 14px;
            font-weight: 600;
        }


    }

    .ticket-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .ticket-box {
        // background-color: #FFFFFF;
        border: 1px solid #EFF0F1;
        border-radius: 10px;
        padding: 8px;
        height: 215px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 6px;

        .heading-container {
            display: flex;
            justify-content: space-between;
        }

        .calender-info {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #53545E;
            padding-top: 8px;
        }

        .creater-name {
            padding: 10px 0;
            color: #000;
            font-size: 16px;
            font-weight: 400;
        }


        .date-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;

            .date {
                font-size: 14px;
                font-weight: 400;
                color: #000;
            }
        }

    }
}

.ljp-card {
    .ljp-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .ljp-details-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 210px;
        overflow-y: scroll;
    }

    .ljp-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        background-color: #FFFFFF;
        border: 2px dashed #EFF0F1;
        border-radius: 10px;
        padding: 10px;



        .ljp-wrapper {
            display: flex;
            gap: 15px;
            width: 145px;

            .job-title {
                max-width: 145px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .ljp-heading {
            padding-bottom: 8px;
            font-size: 16px;
            font-weight: 300;
            color: #000;
        }



        .ljp-details {
            font-weight: 400;
            font-size: 16px;
            color: #000;

            &.approved {
                color: #0D9A0D;
            }

            &.rejected {
                color: #E70D0D;
            }
        }

    }
}

.who-is-in-detail-card {
    .who-is-in-container {
        .who-is-in-box {
            background-color: #FFFFFF;
            padding: 8px;
            border: 1px solid #EDEEF1;
            border-radius: 20px;
            margin: 0 6px 6px;

            .inner-card {
                background-color: #F8F0EB;
                border-radius: 15px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .name-and-status-information {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 12px;
                    border-bottom: 2px dashed #D1D1D1;

                    .status-box {
                        padding: 6px 12px;
                        background-color: #FFFFFF;
                        border-radius: 20px;
                    }
                }

                .who-is-in-details {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    background-color: #FFFFFF;
                    border-radius: 50px;
                    padding: 5px 0;
                }
            }
        }

    }
}

/*==========================
Policies Card Css Start 
============================*/

.police-card {
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 4px;

    .polices-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        padding-top: 20px;

        .polices-child-card {
            width: 33.3%;
            border-radius: 10px;
            padding: 10px;
            height: 320px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-image: url(../../../assets/images/employeedashboard/dash-board-icons/policesicon.png);
            background-position: right bottom;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;

            img {
                height: 100px;
                width: 70px;
                object-fit: contain;
            }

            .nav-wrapper {
                h4 {
                    padding-bottom: 50px;

                }
            }
        }

        .policies-no-data-message {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
}

/*==========================
Policies Card Css End 
============================*/

/*===========================
Toperrs section css start
=============================*/

.toppers-card {
    background-color: #FCFCFD;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    max-height: 290px;
    min-height: 290px;
    border: 1px solid #DFDFDF;
}

.toppers-view-more {
    padding: 15px 0 0;
}


@media (min-width: 768px) and (max-width: 991px) {
    .toppers-card {
        gap: 0;
    }
}

.tl-toppers-card {

    background-color: #FCFCFD;
    max-height: 300px;
    min-height: 300px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .tl-toppers-card-info-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 2px solid $welcome-dashboard-primary-color;
        background: radial-gradient(circle, rgba(15, 93, 176, 1) 0%, rgba(4, 25, 72, 1) 90%);
    }
}

.toppers-image-wrapper {
    img {
        height: 20px;
        width: 20px;
    }
}

.toppers-card-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
    align-items: center;
    width: 60px;
    height: 290px;
    background: radial-gradient(circle, rgba(15, 93, 176, 1) 0%, rgba(4, 25, 72, 1) 90%);
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

    h2 {
        transform: rotate(-90deg);
        color: #C4C4C5;
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 900;
        color: #FAB415;
    }
}

.toppers-wrapper {
    display: flex;
    align-items: center;
    gap: 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .toppers-wrapper {
        gap: 8px;
    }
}

.tl-toppers-wrapper {
    display: flex;
    align-items: center;
    gap: 14px;
    border-bottom: 2px solid #EFF0F1;
    border-right: 2px solid #EFF0F1;
    border-left: 2px solid #EFF0F1;
    flex-grow: 1;
    padding: 15px;
}

// .toppers-box {
//     box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
//     background-color: #ffffff;
//     padding: 10px;
//     border-radius: 20px;
//     width: 200px;
//     height: 215px;

//     .toppers-img-box {
//         position: relative;

//         img {
//             width: 180px;
//             height: 130px;
//             border-radius: 20px 20px 0 0;
//             object-fit: cover;
//             object-position: top;
//             pointer-events: none;
//         }
//     }

//     .toppers-content {
//         text-align: center;

//         .toppers-name {
//             font-weight: 500;
//             font-size: 16px;
//             padding: 10px;
//         }

//         .toppers-score {
//             font-size: 16px;
//             font-weight: 600;
//         }
//     }
// }

/*===========================
Toperrs section css start
=============================*/


.new-joinees-card {
    background-color: #FAF8FF;
    background-image: url(../../../assets/images/employeedashboard/dash-board-icons/new-joinee-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;

    .new-joinees-wrapper {
        padding-left: 45px;

        .new-joinee-container {
            padding-top: 40px;

            .new-joinees-content {
                .joinee-title {
                    padding-bottom: 20px;
                }

                .swiper-btn-groups {
                    display: flex;
                    gap: 15px;

                    .swiper-btn {
                        background-color: white;
                        border: 1px solid #e5e7eb;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

                        padding-top: 4px;

                        padding-bottom: 4px;
                        max-width: 40px;
                        max-height: 40px;
                        width: 40px;

                        height: 40px;
                        cursor: pointer;
                    }

                    .animate-swiper {
                        animation: swiper-outer 3000ms infinite;
                    }
                }
            }

            .new-joinee-section-wrapper {
                .new-joinees-list-wrapper {}

            }

            .mySwiper {
                height: 340px;

                .swiper-slide {
                    padding-top: 70px;
                }
            }
        }
    }

}

.new-joinees-information {
    background-color: #FFFFFF;
    width: 230px;
    height: 225px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 26px;
    position: relative;

    .new-joinees-profile {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 7px;
        align-items: center;

        img {
            height: 100px;
            width: 100px;
            border-radius: 50px;
            object-fit: cover;
            object-position: top;
            pointer-events: none;
        }

        .new-joinee-name {
            color: #000;
            font-size: 15px;
            font-weight: 400;
        }
    }

    .new-joinee-details {
        padding-top: 90px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-left: 25px;
        grid-row-gap: 13px;

        .new-joinee-details-heading {
            font-size: 16px;
            font-weight: 300;
            padding-bottom: 10px;
            color: #000;
        }

        .new-joinee-results {
            font-weight: 400;
            font-size: 16px;
            color: #000;
        }
    }
}



/*================================
Employee profile section css end
==================================*/



/*==============================
Document section modal css start
=================================*/
.document-box {
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #EFF0F1;
    margin: 6px 6px;
    height: 130px;


    .document-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .document-title {
        font-weight: 400;
        font-size: 16px;
        color: #000;
        padding: 8px 0;
    }
}

/*==============================
Document section modal css end
=================================*/

/*==================================
Responsive css for card padding start
====================================*/

@media (min-width: 992px) {
    .t1 {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .custom_padding_0 {
        padding: 0;
    }
}

@media (min-width: 992px) {
    .custom_padding_left_0 {
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .as {
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .custom_padding_right_0 {
        padding-right: 0;
    }
}

/*=========================
Responsive css for card padding End
==========================*/

/*=============================
Signed document modal css start
===============================*/
.modal-signed-doc-box {
    display: flex;
    gap: 20px;
    background-color: #FFFFFF;
    border: 2px solid #EFF0F1;
    border-radius: 10px;
    padding: 15px;

    .modal-signed-doc-detail-info {
        .modal-signed-doc-title {
            padding-bottom: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #000;
        }

        .modal-signed-doc-value {
            font-size: 16px;
            font-weight: 300;
            color: #000;
            max-width: 250px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

/*=============================
Signed document modal css end
===============================*/

.birthday-anniversary-card {
    max-height: 360px;
    min-height: 360px;

    .birthday-seppartion {
        border: 1px solid #EFF0F1;
    }

    .nav-pills .nav-item {
        position: relative;

        .nav-link {
            color: #000;
            font-size: 14px;
            font-weight: 600;

            &.active {
                background-color: #ffffff;
                border-bottom: 2px solid #013979;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .rounded-circle-mic {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DF1111;
        width: 10px;
        height: 10px;
        border-radius: 25px;
        background: #DF1111;
        top: 0px;
        left: 130px;
        box-shadow: 0 0 20px rgba(1, 41, 112, 0.08);
        animation: phone-outer 3000ms infinite;
    }

    .birthday-cardwrapper {
        .birthday-slider {
            max-height: 280px;
            min-height: 280px;

            .swiper-slide {
                height: 90px !important;
            }
        }

        .birthday-anniversary-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background-color: #D0EBE3;
            padding: 10px;
            border-radius: 10px;
            height: 90px;
            background-image: url('../../../assets/images//employeedashboard/dash-board-icons/clelebration.png');
            background-position: bottom right;
            background-repeat: no-repeat;

            .birthday-anniversary-details {
                display: flex;
                align-items: center;
                gap: 15px;

                img {
                    height: 65px;
                    width: 65px;
                    border-radius: 50px;
                    object-fit: cover;
                    gap: 10px;
                }

                .birthday-person-info {
                    .event-person-name {
                        font-weight: 400;
                        font-size: 16px;
                        color: #000;
                    }
                }

                .event-person-id {
                    font-weight: 400;
                    font-size: 16px;
                    color: #000;
                }
            }
        }
    }
}


.modal-score {
    width: 100%;
}

.score-table {
    width: 110%;
}

.who-is-in-wrapper {
    height: 300px;
    overflow-y: auto;
}


.demo-table-heading-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0px 5px;
    margin-bottom: 10px;

    .demo-table-heading {
        font-size: 16px;
        font-weight: 300;
        padding-bottom: 5px;
        color: #000;
    }

    .demo-table-values {
        font-weight: 400;
        font-size: 16px;
        color: #000;
    }
}


.demo-table-result-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 10px;
    background-color: #FFFFFF;
    border-radius: 7px;
    height: 50px;
    align-content: center;
    border: 2px dashed #EFF0F1;
    padding: 0px 5px;
    margin: 8px 0;
}

/* Animation css for birthday announcement */
@keyframes phone-outer {

    0% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow: 0 0 0 0em #FF2323, 0em 0.05em 0.1em rgba(#000000, 0.2);
    }

    33.3333% {
        transform: translate3d(0, 0, 0) scale(1.1);
        box-shadow: 0 0 0 0em #FF2323,
            0em 0.05em 0.1em rgba(#000000, 0.5);
    }

    66.6666% {
        transform: translate3d(0, 0, 0) scale(1);

        box-shadow: 0 0 0 0.5em #FF2323,

            0em 0.05em 0.1em rgba(110, 62, 62, 0.32);
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1);

        box-shadow: 0 0 0 0em #FF2323, 0em 0.05em 0.1em rgba(#000000, 0.2);
    }
}

/* Animation css for birthday announcement */


@keyframes swiper-outer {

    0% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow: 0 0 0 0em rgba(0, 0, 0, 0.1), 0em 0.05em 0.1em rgba(#000000, 0.2);
    }

    33.3333% {
        transform: translate3d(0, 0, 0) scale(1.1);
        box-shadow: 0 0 0 0em rgba(0, 0, 0, 0.1),
            0em 0.05em 0.1em rgba(#000000, 0.5);
    }

    66.6666% {
        transform: translate3d(0, 0, 0) scale(1);

        box-shadow: 0 0 0 0.5em rgba(0, 0, 0, 0.1),

            0em 0.05em 0.1em rgba(110, 62, 62, 0.32);
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1);

        box-shadow: 0 0 0 0em rgba(0, 0, 0, 0.1), 0em 0.05em 0.1em rgba(#000000, 0.2);
    }
}

.employee-calendar-card {
    padding: 10px;

    .employee-calendar-wrapper {
        border: 2px solid #EFF0F1;

        .MuiDateCalendar-root {
            width: 100%;
            background-color: #F4F6FC;
            min-height: 375px !important;

            .MuiPickersCalendarHeader-root {
                margin-top: 0;
                margin-bottom: 0;
            }

            .MuiPickersDay-today {
                color: #fff;
                background-color: #013978;
                border: 1px solid #013978;
            }

            .MuiSvgIcon-root {
                border: 1px solid gray;
                border-radius: 50px;
            }
        }
    }

    .has-holidays {
        border: 2px solid #EFF0F1;
        max-height: 314px;

        .MuiDateCalendar-root {
            width: 100%;
            background-color: #F4F6FC;
            max-height: 310px !important;
            min-height: 310px !important;

            .MuiPickersCalendarHeader-root {
                margin-top: 0;
                margin-bottom: 0;
            }

            .MuiPickersDay-today {
                color: #fff;
                background-color: #013978;
                border: 1px solid #013978;
            }

            .MuiSvgIcon-root {
                border: 1px solid gray;
                border-radius: 50px;
            }
        }
    }

    .holidays-list {
        background-color: #EEE8D9;
        padding: 5px 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;

        .special-day {
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            background-color: #FDB712;
        }
    }
}


.dropdown__container {
    position: relative;
}

.dropdown__content {
    padding: 10px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(-20px);
    transition: 0.23s linear;
    border: 1px solid #dedede;
    position: absolute;
    top: 40px;
    right: 0;
    font-size: 16px;
    min-width: 150px;
    background: #fff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 0;
    transform: translateY(-5px);
    z-index: 999;
}

.dropdown__content:after {
    content: "";
    position: absolute;
    top: -5px;
    right: 17px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
}

.dropdown__content.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    pointer-events: auto;
}

.dropdown__info {
    width: 400px;
    height: 400px;
}

.employee-dashboard-background {
    margin-left: 50px;
    padding: 30px 0px;
    height: 100%;
    min-height: 100vh;

}

.goals-card {
    .goals-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 300px;
        overflow-y: auto;

        .goals {
            padding: 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;

            .MuiSlider-sizeMedium {
                width: 100px;
            }

            .goals-title {
                font-weight: 500;
                font-size: 16px;
                color: #000000;
            }

            .goals-description {
                color: #53545E;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}


.dashboard-header-section {
    margin-top: 32px;
    position: relative;

    .dashboard-header-container {
        padding-top: 15px;
        padding-right: 12px;
        padding-left: 12px;

        &.header-background {
            height: 130px;
            background-color: #F2DDBE;
            background-image: url('../../images/employeedashboard/dash-board-icons/backgroundImageLeft.svg'), url('../../images/employeedashboard/dash-board-icons/backgroundImageRight.svg'), url('../../images/employeedashboard/dash-board-icons/backgroundImageMiddle.svg'), url('../../images/employeedashboard/dash-board-icons/backgroundImageRightAngle.svg'), url('../../images/employeedashboard/dash-board-icons/backgroundImageRectangle.svg');
            background-position: left bottom, right bottom, 66% 100%, right center, 85% 20%;
            background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
            background-size: contain, contain, 50px, 30px, 20px;
        }

        .welcome-title-container {
            .welcome-title {
                font-size: 20px;
                color: #000;
                padding-bottom: 5px;
            }
        }

        .tabs-section {
            .bg_box_shadow_Tab {
                background: #fff;
                border-radius: 10px;
                box-shadow: 0 0 20px #01297014;
                padding: 6px;
                width: fit-content;
                gap: 10px;
            }

            .nav-pills .nav-link {
                color: #090909;
                font-size: 14px;
                font-weight: 600;
                border: 1px solid #EFF0F1;
                width: 150px;
                text-align: center;
                border-radius: 10px;

                &.active {
                    // border-radius: 10px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }


}



.exceptioal-performance-card {
    max-height: 360px;
    min-height: 360px;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .exceptioal-performance-wrapper {
        display: flex;
        gap: 20px;
        align-items: center;
        border-bottom: 2px solid #EFF0F1;
        border-right: 2px solid #EFF0F1;
        border-left: 2px solid #EFF0F1;
        padding: 15px;
        flex-grow: 1;
    }
}


.exceptional-performance-image-box {
    height: 258px;
    width: 200px;
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: transform 0.5s;

    &:hover {
        transform: scale(1.1);
        z-index: 100;
    }

    img {
        height: 258px;
        width: 100%;
        border-radius: 25px;
        object-fit: cover;
        object-position: top;
    }

    &::before {
        content: "";
        background: linear-gradient(180deg, rgba(1, 57, 121, 0) 20%, rgb(1, 57, 121) 90%);
        bottom: 0;
        top: 0;
        width: 100%;
        position: absolute;
        border-radius: 0 0 25px 25px;
    }

    .exceptional-performance-details {
        color: #FFFFFF;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;

        .exceptiona-text {
            font-size: 16px;
            font-weight: 500;
        }

        .person-name {
            padding-bottom: 5px;
        }
    }
}

.leader-card {
    background-color: #E3F1F5;
    border: 1px solid #DFDFDF;
    display: flex;
    gap: 15px;
    padding: 20px 10px;
    border-radius: 4px;
    min-height: 290px;


    .leader-heading-container {
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .leader-title {
            transform: rotate(-90deg);
            color: #041a4a;
            text-transform: uppercase;
            font-size: 28px;
            font-weight: 700;
        }
    }
}

.leader-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 96%;
    position: relative;

    .leader_board_divider {
        border-bottom: 1px dashed #104481;
    }

    .mySwiper_leader_boards {
        .swiper-wrapper {
            flex-direction: row !important;
            row-gap: 6px;
            column-gap: 16px;
            height: 700px;
            margin-top: 20px;
            margin-bottom: 10px;
            margin-left: 20px;

            .swiper-slide {
                width: 213px !important;
                height: 215px !important;
            }
        }
    }

    .leader-image-box {
        height: 215px;
        width: 200px;
        position: relative;
        border-radius: 23px;
        cursor: pointer;
        transition: transform 0.5s;

        &:hover {
            transform: scale(1.2);
            z-index: 100;
        }

        img {
            height: 215px;
            width: 200px;
            border-radius: 23px;
            object-fit: cover;
            object-position: top;
        }

        &::before {
            content: "";
            background: linear-gradient(180deg, rgba(1, 57, 121, 0) 20%, rgb(1, 57, 121) 90%);
            bottom: 0;
            top: 0;
            width: 100%;
            position: absolute;
            border-radius: 0 0 25px 25px;
        }

        .leader-details {
            color: #FFFFFF;
            position: absolute;
            bottom: 8px;
            left: 0;
            right: 0;
            text-align: center;

            .leader-name {
                font-size: 16px;
                font-weight: 600;
            }

            .leader-designation {
                font-size: 14px;
                font-weight: 500;
            }

            .leader-department {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .left-swiper-btn {
        align-items: center;
        background-color: #fff;
        border: 1px solid #e5e7eb;
        border-radius: 50%;
        box-shadow: 0 1px 2px #0000001a;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        max-height: 40px;
        max-width: 40px;
        padding-bottom: 4px;
        padding-top: 4px;
        width: 40px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%);
        z-index: 1;
    }

    .right-swiper-btn {
        align-items: center;
        background-color: #fff;
        border: 1px solid #e5e7eb;
        border-radius: 50%;
        box-shadow: 0 1px 2px #0000001a;
        cursor: pointer;
        display: flex;
        height: 40px;
        justify-content: center;
        max-height: 40px;
        max-width: 40px;
        padding-bottom: 4px;
        padding-top: 4px;
        width: 40px;
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translate(-50%, -50%);
        z-index: 1;
    }

}


/*=========================
Star Performer css start
===========================*/

.star-performer-wrapper {
    padding-top: 50px;
    display: flex;
    gap: 30px;
    align-items: center;
    border-bottom: 2px solid #EFF0F1;
    border-right: 2px solid #EFF0F1;
    border-left: 2px solid #EFF0F1;
    padding: 50px 0 0 15px;
    flex-grow: 1;
}

/* card css start */
.performer-child-cards {
    background-color: #fff;
    border-radius: 26px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: 210px;
    position: relative;
    width: 200px;

    &.perfromer-border {
        border: 2px solid #DAE9FB;
    }

    .star-performer {
        align-items: center;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);

        img {
            border-radius: 50px;
            height: 100px;
            object-fit: cover;
            object-position: top;
            width: 100px;
        }
    }

    .performer-details {
        height: 200px;
        display: flex;
        text-align: center;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        gap: 25px;
        padding-bottom: 20px;
    }

    .performer-name {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        padding-bottom: 6px;
    }

    .performer-department {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
    }

    .performer-date {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
    }
}

/* card css end */

/*=========================
Star Performer css End
===========================*/


/*========================
Star Team Css Start
==========================*/

.star-team-card {
    max-height: 360px;
    min-height: 360px;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;


    .star-team-wrapper {
        padding-top: 50px;
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid #EFF0F1;
        border-right: 2px solid #EFF0F1;
        border-left: 2px solid #EFF0F1;
        flex-grow: 1;

        .star-team-child-cards {
            background-color: #fff;
            border-radius: 26px;
            box-shadow: 0 8px 24px rgba(149, 157, 165, 0.2);
            height: 210px;
            position: relative;
            width: 230px;

            .star-team-profile {
                display: flex;
                justify-content: center;
                align-items: center;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
                border-radius: 50px;
                height: 100px;
                object-fit: cover;
                width: 100px;
                background-color: #FFFFFF;
                border: 2px solid #CAC3DB;
            }

            .star-team-details {
                height: 200px;
                display: flex;
                text-align: center;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                gap: 25px;
                padding-bottom: 20px;

                .star-team-name {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    padding-bottom: 6px;
                }

                .star-team-department {
                    font-size: 16px;
                    font-weight: 500;
                    color: #000000;
                }
            }

            .star-team-button {
                display: flex;
                align-items: center;
                gap: 10px;
                background-color: #ffffff;
                color: #000000;
                border: 1px solid #EEEEEE;
                border-radius: 15px;
                font-size: 15px;
                font-weight: 500;
            }
        }
    }
}

// Star Team Modal start

.star-team-modal {
    padding: 0;

    .star-team-container {
        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}

// Star Team Modal End

/*========================
Star Team Css End
==========================*/


/*===========================
Promotion Css Start
=============================*/

.promotion-card {
    max-height: 300px;
    min-height: 300px;
    background-color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .promotion-wrapper {
        display: flex;
        gap: 10px;
        border-bottom: 2px solid #EFF0F1;
        border-right: 2px solid #EFF0F1;
        border-left: 2px solid #EFF0F1;
        padding: 15px;
        flex-grow: 1;
    }
}

.promotion-box {
    display: flex;
    align-items: center;
    background-color: #F7F6FD;
    padding: 15px;
    border: 1px solid #EFF0F1;
    border-radius: 10px;
    gap: 10px;
    width: 33.3%;

    .promotion-image {
        img {
            height: 176px;
            width: 125px;
            border-radius: 20px 0 0 20px;
            object-fit: cover;
        }
    }

    .promotion-content {
        .promoted-person-name {
            font-size: 16px;
            font-weight: 800;
            color: #000000;
        }

        .promoted-designation {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
            padding: 20px 0;
        }

        .button-view {
            color: #013979;
            background-color: #FFFFFF;
            border: 1px solid #EEEEEE;
            padding: 5px 20px;
            border-radius: 12px;
        }
    }
}


/*===========================
Promotion Css End
=============================*/

.announcement-section {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -60px;
    display: flex;
    justify-content: space-around;


    .ijp-announcemet-card {
        padding: 10px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        border-radius: 12px;
        flex: 1 0 200px;

        &.ijp-bg {
            background-image: url(../../../assets/images/internalJobPost/apply.png);
            background-size: auto;
            background-position: bottom right;
            background-repeat: no-repeat;
        }

        &.announcement-bg {
            background-image: url(../../../assets/images/employeedashboard/dash-board-icons/announcement.png);
            background-size: auto;
            background-position: bottom right;
            background-repeat: no-repeat;
        }

        .announcement-title {
            color: #9B897E;
        }

        .announcement-name {
            font-size: 18px;
            font-weight: 500;
            color: #000000;
        }

        .announcement-btn {
            padding: 0;
            background-color: transparent;
            color: #000000;
            border: none;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}

.topgrid-section {
    margin-top: 20px;
}

.employee-welcome-screen-bg {
    padding-top: 60px;
}

.welcome-screen {
    padding-left: 12px;
    padding-right: 12px;
}

.percentage-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #000000;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    padding: 2px;
    font-size: 14px;
    font-weight: 700;
}

.icon-backgrond {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 10px;
    background-color: #f5f7f9;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    opacity: 0;
    transition: opacity ease-in-out 0.3s;
}


.tl-image-box {
    height: 210px;
    width: 200px;
    border-radius: 25px;
    position: relative;
    transition: transform 0.5s;

    &:hover {
        transform: scale(1.1);
        z-index: 100;

        .icon-backgrond {
            opacity: 1;
        }
    }

    img {
        height: 210px;
        width: 100%;
        border-radius: 25px;
        object-fit: cover;
        object-position: top;
    }

    &::before {
        content: "";
        background: linear-gradient(180deg, rgba(1, 57, 121, 0) 20%, rgb(1, 57, 121) 90%);
        bottom: 0;
        top: 0;
        width: 100%;
        position: absolute;
        border-radius: 0 0 25px 25px;
    }

    .tl-details {
        color: #FFFFFF;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        text-align: center;

        .exceptiona-text {
            font-size: 16px;
            font-weight: 500;
        }

        .person-name {
            padding-bottom: 5px;
        }
    }

}