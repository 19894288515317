.exceptioal-performance-page-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    .exceptional-image-box {
        height: 258px;
        width: 200px;
        border-radius: 25px;
        position: relative;

        img {
            height: 258px;
            width: 100%;
            border-radius: 25px;
            object-fit: cover;
        }

        &::before {
            content: "";
            background: linear-gradient(180deg, rgba(1, 57, 121, 0) 20%, rgb(1, 57, 121) 90%);
            bottom: 0;
            top: 0;
            width: 100%;
            position: absolute;
            border-radius: 0 0 25px 25px;
        }

        .exceptional-details {
            color: #FFFFFF;
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            text-align: center;

            .exceptiona-text {
                font-size: 16px;
                font-weight: 500;
            }

            .person-name {
                padding-bottom: 5px;
            }
        }
    }
}