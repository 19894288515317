.candidate-management {
  .input-border {
    &:before {
      left: 0 !important;
      top: 0 !important;
    }


  }
}

.dotted-select {
  .css-1im77uy-control {
    min-height: 40px;
    margin-left: 3px;
    border: 1px dashed #d2d4da;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-transform: capitalize;
  }

  .css-10lk5e0-control {
    min-height: 40px;
    margin-left: 3px;
    border: 1px dashed #d2d4da;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .css-hmad3w-control {
    min-height: 40px;
    margin-left: 3px;
    border: 1px dashed #d2d4da;
    box-shadow: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
  }

  .css-1kh0o5-menu {
    background-color: #fff !important;
  }

  .css-1jqq78o-placeholder {
    font-size: 14px;
  }
}

.dotted-select-filter {
  .css-1im77uy-control {
    min-height: 40px;
    margin-left: 3px;
    border: 1px dashed #d2d4da;
    border-radius: 5px;
  }

  .css-10lk5e0-control {
    min-height: 40px;
    margin-left: 3px;
    border: 1px dashed #d2d4da;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .css-hmad3w-control {
    min-height: 40px;
    margin-left: 3px;
    border: 1px dashed #d2d4da;
    box-shadow: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
  }

  .css-1kh0o5-menu {
    background-color: #fff !important;
  }

  .css-1jqq78o-placeholder {
    font-size: 14px;
  }
}

// search group start 

.search-group {
  min-height: 45px;
  position: relative;

  .search-bar {
    // padding-left: 45px;
    // border-left: 0;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;

    &:focus {
      box-shadow: none;
    }

    .wrapper {
      position: relative;
      border: none;
      display: block;

      input {
        max-width: 424px;
        min-width: 424px;
        font-size: 14px;
        padding-left: 40px;
      }

      .search-icon {
        display: none;
      }

      .clear-icon {
        display: none;
      }
    }

    .sc-gEvEer {
      font-size: 14px;
      border: 1px dashed #d2d4da;
    }

    .wrapper:active {
      box-shadow: none;
    }

    .wrapper:hover {
      box-shadow: none;
    }

    .wrapper:focus-within {
      box-shadow: none;
    }
  }

  .left-text {
    border: 1px dashed #d2d4da;
    background-color: #fdb712;
    padding: 0.375rem 15px;
    position: absolute;
    right: 0;
    top: 0px;

    height: 46px;
  }

  .right-text {
    padding-left: 20px;
    background: transparent;
    border: none;
  }

  .user-wrapper {
    position: absolute;
    width: 26px;
    height: 26px;
    background: #d7dfe8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px !important;
    top: 50%;
    margin-top: -19px;
    left: 7px;

    img {
      width: 10px;
    }
  }
}

// search group end

// Datepicker start

.date-picker-group {
  .input-border-bordered {

    // &::before {
    //   top: 8px !important;
    // }
    .MuiStack-root {
      padding-top: 0 !important;
      background-color: #fff;

      &:hover {
        border-color: transparent;
        border: none !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border: 1px solid #d2d4da !important;
      border-radius: 0;
    }

    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
      border: 1px solid #d2d4da !important;
    }
  }

  .input-border {

    // &::before {
    //   top: 8px !important;
    // }
    .MuiStack-root {
      padding-top: 0 !important;
    }
  }

  .input-border-common {

    // &::before {
    //   top: 8px !important;
    // }
    .MuiStack-root {
      padding-top: 0 !important;
    }
  }

  // .MuiStack-root {
  //   width: 200px;
  // }

  .MuiFormControl-root {
    min-width: 200px !important;
  }

  .MuiInputBase-root {
    height: 40px;
    line-height: 0 !important;

    &::placeholder {
      color: #696a72;
    }

    // min-width: 410px;

    // border: 1px dashed #d2d4da;
    @media (max-width: 1399px) {
      min-width: 100%;
    }
  }

  .MuiButtonBase-root {
    background-color: transparent !important;
    color: #000 !important;
  }

  .MuiInputBase-root:hover {
    border: none !important;
    // border: 1px solid red !important;
  }

  .disabled-input {
    background-color: #f0f0f0;
    /* Example of a lighter background color */
    color: #999;
    /* Example of a lighter text color */
    cursor: not-allowed;
    /* Example of a disabled cursor style */
  }

  .MuiInputBase-input {
    padding: 0px 14px;
    font-size: 14px;
    padding-right: 0;
    color: #0d0d0e;

    &::placeholder {
      color: #3e3d3d !important;
    }
  }

  .MuiOutlinedInput-root {
    input {}
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border: 1px dashed #d2d4da !important;
  }



  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border: 1px dashed #d2d4da !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border: 1px dashed #d2d4da !important;
  }

  .MuiTextField-root {
    width: 100%;
  }
}

.date-picker-group-performance {
  .input-border {

    // &::before {
    //   top: 8px !important;
    // }
    .MuiStack-root {
      padding-top: 0 !important;
    }
  }

  // .MuiStack-root {
  //   width: 200px;
  // }

  .MuiFormControl-root {
    min-width: 200px !important;
    background-color: #fff !important;
  }

  .MuiInputBase-root {
    height: 40px;
    line-height: 0 !important;

    &::placeholder {
      color: #696a72;
    }

    // min-width: 410px;

    // border: 1px dashed #d2d4da;
    @media (max-width: 1399px) {
      min-width: 100%;
    }
  }

  .MuiButtonBase-root {
    background-color: transparent !important;
    color: #000 !important;
  }

  .MuiInputBase-root:hover {
    border: none !important;
    // border: 1px solid red !important;
  }

  .disabled-input {
    background-color: #f0f0f0;
    /* Example of a lighter background color */
    color: #999;
    /* Example of a lighter text color */
    cursor: not-allowed;
    /* Example of a disabled cursor style */
  }

  .MuiInputBase-input {
    padding: 0px 14px;
    font-size: 14px;
    padding-right: 0;
    color: #0d0d0e;

    &::placeholder {
      color: #3e3d3d !important;
    }
  }

  .MuiOutlinedInput-root {
    input {}
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
  }



  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
  }

  .MuiTextField-root {
    width: 100%;
  }
}

.date-picker-group-event {
  .input-border {

    // &::before {
    //   top: 8px !important;
    // }
    .MuiStack-root {
      padding-top: 0 !important;
    }
  }



  .MuiInputBase-root {
    height: 40px;
    line-height: 0 !important;

    &::placeholder {
      color: #696a72;
    }

    // min-width: 410px;

    // border: 1px dashed #d2d4da;
    @media (max-width: 1399px) {
      min-width: 100%;
    }
  }

  .MuiButtonBase-root {
    background-color: transparent !important;
    color: #000 !important;
  }

  .MuiInputBase-root:hover {
    border: none !important;
    // border: 1px solid red !important;
  }

  .disabled-input {
    background-color: #f0f0f0;
    /* Example of a lighter background color */
    color: #999;
    /* Example of a lighter text color */
    cursor: not-allowed;
    /* Example of a disabled cursor style */
  }

  .MuiInputBase-input {
    padding: 0px 14px;
    font-size: 14px;
    padding-right: 0;
    color: #0d0d0e;

    &::placeholder {
      color: #3e3d3d !important;
    }
  }

  .MuiOutlinedInput-root {
    input {}
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border: 1px dashed #d2d4da !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    border: 1px dashed #d2d4da !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    border: 1px dashed #d2d4da !important;
  }

  .MuiTextField-root {
    width: 100%;
  }
}

// Datepicker end

// /file-upload-group start

.file-upload-group {
  .ant-upload {
    width: 100px;
    height: 100px;

    &:focus {
      outline: none !important;
    }

    .anticon {
      display: none;
    }

    .upload-icon-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      img {
        width: 40px;
      }
    }
  }

  .ant-upload-drag {
    border: 1px dashed #d2d4da;
    background-color: #fff;
  }
}

// /file-upload-group end

// view candidate page start
.profile-details-wrapper-bg-yellow {
  background-color: #fab514;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 10px 15px;
  margin-top: 30px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .img-wrapper {
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 8px;
    }

    .profile-name {
      font-size: 15px;
      font-weight: 700;
      // padding-left: 15px;
    }

    .dpt-name {
      font-size: 15px;
      font-weight: 700;
      padding-left: 15px;
    }
  }

  .delete-sec {
    cursor: pointer;
    font-size: 13px;
    display: flex;
    gap: 10px;
    background-color: #fff;
    right: 40px;
    bottom: 20px;
    padding: 2px 7px;
    border-radius: 5px;
    min-width: 70px;
    justify-content: center;
  }
}

.fs-14 {
  .profile-name {
    font-size: 14px !important;
  }
}

.profile-details-wrapper {
  background-color: #d7dfe8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-top: 30px;

  .img-wrapper {
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 8px;
    }

    .profile-name {
      font-size: 15px;
      font-weight: 700;
      padding-left: 15px;
    }

    .dpt-name {
      font-size: 15px;
      font-weight: 700;
      padding-left: 15px;
    }
  }

  .delete-sec {
    cursor: pointer;
    font-size: 13px;
    display: flex;
    gap: 10px;
    background-color: #fff;
    right: 40px;
    bottom: 20px;
    padding: 2px 7px;
    border-radius: 5px;
    min-width: 70px;
    justify-content: center;
  }
}

.view-section {
  padding-bottom: 30px;

  .view-title {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding-top: 14px;
    padding-left: 15px;
    display: flex;
    gap: 10px;

    &::before {
      position: absolute;
      content: "";
      background-color: #779be1;
      height: 32px;
      width: 3px;
      left: 0;
      top: 9px;
    }

    .edit-icon-wrapper {
      background-color: #f5f5f5;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      img {
        width: 12px;
      }
    }
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 30px 0px;
    // padding-top: 30px;

    .single-grid {
      .input {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        padding-right: 10px;
      }

      .red-text {
        color: #cb0000;
      }

      .label {
        color: #242529;
        font-size: 14px;
      }
    }

    .file-img-view {
      background-color: #eee;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }

  .grid-wrapper-custm {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 30px 0px;
    padding-top: 30px;

    .single-grid {
      .input {
        color: #000000;
        font-size: 14px;
        max-width: 200px;
        font-weight: 600;
        padding-right: 10px;

        .contents {
          ul {
            margin-left: 12px;
          }
        }

      }

      .red-text {
        color: #cb0000;
      }

      .label {
        color: #242529;
        font-size: 14px;
      }
    }

    .file-img-view {
      background-color: #eee;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }

  .grid-wrapper-custm_12 {
    padding-top: 30px;

    .single-grid {
      .input {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        padding-right: 10px;

        .contents {
          ul {
            margin-left: 20px;
          }
        }

      }

      .red-text {
        color: #cb0000;
      }

      .label {
        color: #242529;
        font-size: 14px;
      }
    }

    .file-img-view {
      background-color: #eee;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }

  .grid-wrapper-question {
    padding-top: 30px;

    .single-grid {
      .input {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        padding-right: 10px;
      }

      .red-text {
        color: #cb0000;
      }

      .label {
        color: #242529;
        font-size: 14px;
      }
    }

    .file-img-view {
      background-color: #eee;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }

  .grid-wrapper-cv {
    padding-top: 30px;

    .single-grid {
      display: flex;
      gap: 20px;
    }

    .pdf-name {
      color: #53545e;
      font-size: 14px;
    }

    .label {
      color: #53545e;
      font-size: 13px;
      padding-bottom: 8px;
    }

    .cv-details {
      background-color: #f6f8fa;
      max-width: 500px;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 5px;
    }

    .download-wrapper {
      display: flex;
      background-color: #013979;
      border-color: #013979;
      color: #fff;
      padding: 4px 15px;
      border-radius: 5px;
      align-items: center;
      justify-content: center;

      img {
        width: 12px;
        margin-right: 7px;
      }
    }
  }
}

// view candidate page end

// candidate-info starts

.candidate-info {
  .dataTable-header {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: -10px;
  }

  .dataTable-left {
    .filter-title-wrapper {
      border: 1px solid #f0f3f5;
    }

    .dataTable-title-wrap {
      .dataTable-title {
        padding-bottom: 0px;
        width: 160px;
      }
    }

    .input-group {
      border: 1px solid #f0f3f5;
      width: auto;

      .form-control {
        border: none;
        font-size: 14px;
        min-width: 220px;
        position: relative;
        z-index: 0;

        &:focus {
          box-shadow: none;
        }

        &::placeholder {
          font-size: 14px;
        }
      }

      .input-group-text {
        border: none;
        background-color: #fff;
      }
    }

    .cvv-download {
      width: 50px;
      cursor: pointer;
      background: transparent;
      border: none;
      padding: 0;
    }
  }

  .dataTable-right {
    display: flex;
    gap: 10px;

    .btn-broadcast {
      padding: 4px 10px;
      border: 1px solid #f0f3f5;
      background-color: #fff;
      color: #000;
      font-size: 14px;
    }

    .main_title {
      color: #000;
      font-size: 16px;
      font-weight: 500;


    }

    .sub_title {
      color: #013979;
      font-size: 16px;
      font-weight: 600;
      margin-top: 2px;
    }
  }

  .jus-content-end {
    justify-content: flex-end;
  }

  .toggle-btn {
    .form-check-input {
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      &:checked {
        background-color: #013979;
        border-color: #013979;
      }
    }
  }
}

// candidate-info end

// candidate-filter start

.candidate-filter {
  .MuiInputBase-root {
    min-width: 250px !important;
  }

  .css-1im77uy-control,
  .css-1kh0o5-menu,
  .css-hmad3w-control {
    max-width: 250px !important;
  }

  .MuiSlider-rail {
    background-color: #b3bcc7;
  }

  .MuiSlider-thumb {
    background-color: #f5faff;
    border: 2px solid #013979;
    width: 12px;
    height: 12px;
  }

  .MuiSlider-track {
    background-color: #013979;
  }

  .date-picker-group {
    .input-border {
      &::before {
        left: -3px;
      }
    }
  }

  .MuiSlider-root {
    width: 250px;
  }

  .MuiSlider-valueLabel {
    background-color: #000000;
    padding: 2px 7px;
    font-size: 10px;
  }

  .input-border {
    position: relative;
    width: 100%;

    &:before {
      position: absolute;
      content: "";
      height: 40px;
      width: 3px;
      background-color: #698ab0;
      left: 0;
      top: 0;
    }
  }
}

.candidate-only {
  .MuiInputBase-root {
    min-width: 100px !important;
  }

  .MuiSlider-root {
    width: 210px !important;
    margin-right: 5px;
  }
}

// candidate-filter end

.candidate-profile {
  .sc-gEvEer {
    a {
      color: #000 !important;
      text-decoration: none;
    }
  }
}

.candidate-profile-view {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.ant-upload-list-item-undefined {
  display: none !important;
}

.ant-upload-list-text {
  >* {
    display: none;
  }

  > :last-child {
    display: block;
  }
}

.ant-upload-list-item-error {
  .ant-upload-list-item-name {
    color: #ff4d4f !important;
  }
}

.view-file-wrapper {
  display: flex;
  background-color: #013979;
  border-color: #013979;
  color: #fff;
  padding: 4px 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}

// candidate form swal style
.swal2-html-container {
  margin: 1rem 0 !important;

}

.top-middile-content-pop-up {
  font-size: 0.9rem;
  color: rgb(16, 38, 93);
  width: 35%;
  font-weight: bold;

  span {
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.candidate-form-pop-up-headder {
  color: rgb(16, 38, 93);
  padding: 0 1.8rem;

  h2 {
    padding: .8em 1em;
    font-size: 1.1em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
  }
}

.bottom-zapare-link {
  color: rgb(16 38 93);
  font-size: 0.8rem;
  text-align: center;
  padding: 15px;
  background: #d2e7fd;
  max-width: 500px;
  margin: 0px auto 0px;
  border-radius: 0 0 3px 3px;

  span {
    font-weight: bold;
  }
}

@media (max-width: 480px) {
  .top-middile-content-pop-up {
    font-size: 0.7rem;

    span {
      font-size: 0.7rem;
    }
  }

  .candidate-form-pop-up-headder {
    padding: 0 1.8rem;

    h2 {
      padding: .8em 1em;
      font-size: 1em;
    }

    ul {
      font-size: 0.8em;
      line-height: 27px;
    }

    p {
      font-size: 0.8em;
    }
  }
}

.date-picker-group-custom {
  .input-border {

    // &::before {
    //   top: 8px !important;
    // }
    .MuiStack-root {
      padding-top: 0 !important;
    }
  }

  // .MuiStack-root {
  //   width: 200px;
  // }

  .MuiFormControl-root {
    min-width: 200px !important;
  }

  .MuiInputBase-root {
    height: 40px;
    line-height: 0 !important;

    &::placeholder {
      color: #696a72;
    }

    // min-width: 410px;

    // border: 1px dashed #d2d4da;
    @media (max-width: 1399px) {
      min-width: 100%;
    }
  }

  .MuiButtonBase-root {
    background-color: transparent !important;
    color: #000 !important;
  }

  .MuiInputBase-root:hover {
    border: none !important;
    // border: 1px solid red !important;
  }

  .disabled-input {
    background-color: #f0f0f0;
    /* Example of a lighter background color */
    color: #999;
    /* Example of a lighter text color */
    cursor: not-allowed;
    /* Example of a disabled cursor style */
  }

  .MuiInputBase-input {
    padding: 0px 14px;
    font-size: 14px;
    padding-right: 0;
    color: #0d0d0e;

    &::placeholder {
      color: #3e3d3d !important;
    }
  }

  .MuiOutlinedInput-root {
    input {}
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: hsl(0, 0%, 80%) !important;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
  }

}

.candidate-form-submission-container {
  p {
    color: rgb(16, 38, 93);
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding-bottom: 10px;
  }
}