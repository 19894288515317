.promotion-page-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;

    .new-promotion-box {
        display: flex;
        align-items: center;
        background-color: #F7F6FD;
        padding: 15px;
        border: 1px solid #EFF0F1;
        border-radius: 10px;
        gap: 10px;
       

        .promotion-image {
            img {
                height: 176px;
                width: 125px;
                border-radius: 20px 0 0 20px;
                object-fit: cover;
            }
        }

        .promotion-content {
            .promoted-person-name {
                font-size: 16px;
                font-weight: 800;
                color: #000000;
            }

            .promoted-designation {
                font-size: 16px;
                font-weight: 500;
                color: #000000;
                padding: 20px 0;
            }

            .button-view {
                color: #013979;
                background-color: #FFFFFF;
                border: 1px solid #EEEEEE;
                padding: 5px 20px;
                border-radius: 12px;
            }
        }
    }
}