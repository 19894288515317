.tl-toppers-container {
    .tl-toppers-page-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 14px;

        .toppers-toppers-box {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            background-color: #ffffff;
            padding: 10px;
            border-radius: 20px;
            width: 200px;
            height: 215px;

            .toppers-img-box {
                img {
                    width: 180px;
                    height: 130px;
                    border-radius: 20px 20px 0 0;
                    object-fit: cover;
                }
            }

            .toppers-content {
                text-align: center;

                .toppers-name {
                    font-weight: 500;
                    font-size: 16px;
                    padding: 10px;
                }

                .toppers-score {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }
}