@import url(./component-styles/header.scss);
@import url(./component-styles/sidebar.scss);
@import url(./component-styles/login.scss);
@import url(./component-styles/home/home.scss);
@import url(./component-styles/dataTable.scss);
@import url(./component-styles/candidate.scss);
@import url(./component-styles/employee.scss);
@import url(./component-styles/assessment.scss);
@import url(./component-styles/broadcasting.scss);
@import url(./component-styles/TechLead.scss);
@import url(./component-styles/assignShift.scss);
@import url(./component-styles/widget/widget.scss);
@import url(./component-styles/doc-signing/doc-signing.scss);
@import url(./component-styles/doc-signing/template.scss);
@import url(./component-styles/promotion/promotion.scss);
@import url(./component-styles/employeeAttendance/employeeAttendance.scss);
@import url(./component-styles/ijp/ijp.scss);
@import url(./component-styles/payroll/payroll.scss);
@import url(./component-styles/non-appraisal.scss);
@import url(./component-styles/employeeDashboard.scss);
@import url(./component-styles/file-repository.scss);
@import url(./component-styles/assetsSection.scss);
@import url(./component-styles/internalJobPost.scss);
@import url(./component-styles/employeedocumentcard.scss);
@import url(./component-styles/myTeam.scss);
@import url(./component-styles/exceptionalPerformancePage.scss);
@import url(./component-styles/starPerformercePage.scss);
@import url(./component-styles/starTeamPage.scss);
@import url(./component-styles/newPromotionPage.scss);
@import url(./component-styles/tlToppersPage.scss);
@import url(./component-styles/topperspage.scss);

// @import url(./component-styles/home/components/dashboard.scss);
body {
  font-family: "Lato", sans-serif !important;
}

.css-ahj2mt-MuiTypography-root {
  font-family: "Lato", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  margin: 0;
  padding: 0;
}

.dashboard {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #a7abaf;
  border-radius: 4px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar {
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #a7abaf;
}

.container {
  scrollbar-color: #a7abaf #f0f0f0;
  scrollbar-width: thin;
}

.dashboard.question.management {
  .single-grid.internal-job-details-ck-main {
    .input {
      font-weight: revert;
    }

    .internal-job-details-ck-content {

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      ul,
      li,
      p {
        margin: revert;
        padding: revert;
      }

      b,
      strong {
        font-weight: bold;
      }

      input {
        font-weight: revert;
      }
    }
  }
}

.dashboard {
  .announcement-section {

    .job-title-main,
    .announcement-title-main {
      font-size: 18px;

      span.job-title {
        color: #013978;
      }
    }

    .rfm-marquee-container {
      .rfm-marquee {
        min-width: unset !important;
      }
    }
  }

}

.job-exam-section.dashboard {
  .accordion {
    .accordion-item {
      .listing-form label {
        height: 19px;
      }
    }
  }
}

.dotted-input-half {
  border: 1px dashed #d2d4da;
  border-radius: 0;
  position: relative;
  border-left-color: #fff;
  margin-left: 3px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  min-height: 40px;
  color: #696a72;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
    color: #696a72;
  }
}

.dotted-input-half-bg-none {
  border: 1px dashed #d2d4da;
  border-radius: 0;
  position: relative;
  margin-left: 3px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  min-height: 40px;
  color: #696a72;

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
    color: #696a72;
  }
}

.dotted-input {
  border: 1px dashed #d2d4da;
  border-radius: 0;
  position: relative;
  margin-left: 3px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  min-height: 40px;
  color: #696a72;

  &::before {
    position: absolute;
    content: "";
    height: 40px;
    width: 3px;
    background-color: #698ab0;
    left: 0;
    top: 0;
  }

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
    color: #696a72;
  }
}

.dotted-input-common {
  border: 1px dashed #d2d4da;
  border-radius: 5px;
  position: relative;
  margin-left: 3px;
  font-size: 14px;
  min-height: 40px;
  color: #696a72;



  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
    color: #696a72;
  }
}

.common-view-btn {
  background-color: #013979;
  border-color: #013979;
  padding: 2px 13px;
  font-size: 14px;
  margin-top: -9px;
  border-radius: 5px;

  &:hover {
    background-color: #013979;
    border-color: #013979;
  }
}

.input-border-common {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 3px;
    background-color: #698ab0;
    left: 0px;
    top: 0px;
  }
}

.listing-form {
  .input-textarea {
    &::before {
      height: 100% !important;
    }
  }

  .form-check-input:checked {
    background-color: #1f3a6e;
    border-color: #1f3a6e !important;
  }

  .form-check-input:focus {
    box-shadow: none;
  }

  .radio-grp {
    display: flex;
    gap: 40px;
    padding-top: 10px;

    .form-check-input {
      border-color: #afafaf;

      &:checked {
        background-color: #013979;
        border-color: #013979;
      }

      &:focus {
        border-color: #013979;
        box-shadow: 0 0 0 0.25rem rgb(179 188 201 / 25%);
      }

    }
  }
}

.error-message {
  font-size: 0.875em;
  color: #dc3545;
}

.form-control.is-valid {
  background-image: none;
}

// toaster style

.go2072408551 {
  width: 300px;
  height: 120px;
  flex-direction: column;
}

.go685806154 {
  width: 40px;
  height: 40px;
}

.go3958317564 {
  text-align: center;
  align-items: center;
}

// toaster style end

// role tree style start
.sc-gEvEer {
  color: rgb(1 57 121) !important;
  border-color: rgb(1 57 121) !important;

  svg {
    color: #fff !important;
  }
}

.irCbom .sc-gEvEer {
  background-color: rgb(1 57 121) !important;
}

.irCbom:hover .sc-gEvEer {
  background-color: rgb(1 57 121) !important;
}

.eKclsF:hover {
  color: rgb(1 57 121) !important;
  border-color: rgb(1 57 121) !important;
}

.eKclsF:hover {
  border-color: rgb(1 57 121) !important;
}

.eJFaUo .sc-gEvEer {
  background-color: rgb(1 57 121) !important;
}

.eJFaUo:hover .sc-gEvEer {
  background-color: rgb(1 57 121) !important;
}

// role tree style end

.custom-cancel-button-class {
  margin-right: 8px;
  background-color: #779be1;
  border-color: #779be1;
  border: 1px solid #779be1;

  padding: 6px 20px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  min-width: 85px;

  &:hover {
    background-color: #fff;
    border-color: #90ade7;
    color: #000;
  }

  &:focus-visible {
    box-shadow: none;
    background-color: #90ade7;
    border-color: #90ade7;
    color: #fff;
  }
}

.custom-confirm-button-class {
  padding: 6px 20px;
  border: 1px solid #fbb514 !important;
  background-color: #fbb514;
  color: #fff;
  font-size: 14px;
  min-width: 85px;
  box-shadow: none !important;

  &:hover {
    background-color: #fff;
    border-color: #fbb514 !important;
    color: #000;
  }

  &:focus-visible {
    background-color: #fbb514;
  }
}

.marginauto {
  margin: 0 auto;
}

.roles-table {
  .sc-dkrGBB {
    .sc-hLBatu {
      &:last-child {
        justify-content: center;
      }
    }
  }
}

.roles-table {
  .rdt_TableHeadRow {
    &:last-child {
      justify-content: center !important;
    }

  }
}

.rdt_TableHeadRow {
  svg {
    color: #646464 !important;
  }
}

.css-1n9rq6o-Box {
  display: none !important;
}

.typing-test-head {
  font-size: 20px;
  font-weight: 600;
}

.typing-test-result {
  font-size: 16px;
  font-weight: 600;
}

.typing-pass-text {
  color: #19b119;
}

.typing-fail-text {
  color: #db1818;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-view-ticket {
  position: absolute;
  right: -50px;
  background: #013979;
  color: #fff;

  &:hover {
    background: #013979;
    color: #fff;
  }
}

.purchase-clear {
  position: absolute;
  right: 4px;
  top: -26px;
  border: none;
  background: #013979;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 5px;

  svg {
    width: 12px;
    height: 12px;
  }
}

.note-textarea {
  .invalid-feedback {
    position: absolute;
    bottom: -25px;
    left: 90px;
  }

  .text-muted {
    position: absolute;
    bottom: -25px;
  }
}

.slider-clear {
  font-size: 12px;
  text-decoration: underline;
}

.random_typing_test {
  .question-btn {
    padding: 5px 20px;
    text-decoration: none;
    font-size: 14px;
    border-radius: 5px !important;
    min-width: 110px !important;
    background-color: #4273d3 !important;
    border-color: #779be1 !important;
    color: #fff !important;

    &:focus {
      box-shadow: none;
    }
  }

  .btn-wrapper-typing {
    text-align: center;
    padding-top: 30px;
  }
}

.disable-check-input {
  .form-check {
    input {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}

.cand-exam-timer {
  right: 0;
  background-color: #eee;
  padding: 6px 10px;
  z-index: 2;
  border-radius: 5px;
}

.loader-sec {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-card {
  background-color: #1e3a6c;
  color: #ffb633;
  width: 10vw;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 83px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .card-main-val {
    font-size: 22px;
    font-weight: 500;

    @media(max-width:1349px) {
      font-size: 18px;
    }
  }

  @media(max-width:1800px) and (min-width: 1350px) {
    width: 9vw;
  }

  @media(max-width:1349px) {
    width: 120px;
  }
}

.custom-main-card {
  background-color: #edf2fa;
  padding: 15px 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.custom-card-2 {
  background-color: #1d3a6a;
  color: #ffb633;
  padding: 7px 5px 6px 5px;
  border-radius: 5px;
}

.home-no-data {
  // height: calc(364px - 74px);
  height: 90px;
  font-size: 12px;
}

.no-data-found {
  font-size: 12px;
}

.candidate-image-viewer {
  width: 350px;
  margin-left: 30px;
  position: sticky;
  top: 0px;
  right: 0px;
  height: 100vh;
  display: flex;
  align-items: center;

  .image-close-button {
    background-color: #4073cf;
    padding: 5px 10px;
    color: #ffff;
  }
}

.candidate-verify-image-viewer {
  width: 350px;
  margin-left: 30px;
  position: sticky;
  top: 0px;
  right: 0px;
  height: calc(100vh - 150px);

  display: flex;
  align-items: center;

  .image-close-button {
    background-color: #4073cf;
    padding: 5px 10px;
    color: #ffff;
  }
}

.proceed-btn-verify {
  background: #1f3a6e;
  border-color: #1f3a6e;
  padding: 5px 50px;
  width: 100%;
  border-radius: 6px;
  margin-top: 30px;
  color: #fbb514;
}

.input-border-verify {
  position: relative;

  &:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 3px;
    background-color: #698ab0;
    left: 0;
    top: 0;
  }
}

.custom-border-left {
  border-left: 3px solid #698ab0;
}

.capitalize {
  text-transform: capitalize;
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr) !important;
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr) !important;
}

.sc-cwHptR {
  color: #000;
  font-weight: 700;
  font-size: 14px;
}

.hrvalidation {
  .dotted-input-half {
    border-left: 3px solid #779be1;
  }

  .css-1im77uy-control {
    border-left: 3px solid #779be1;
  }

  .css-1im77uy-control:hover {
    border-left: 3px solid #779be1;
  }

  .css-hmad3w-control {
    border-left: 3px solid #779be1;
  }

  .css-10o2lyd-MuiStack-root>.MuiTextField-root,
  .css-10o2lyd-MuiStack-root>.MuiPickersTextField-root {
    border-left: 3px solid #779be1;
  }

  .required {
    color: red;
  }
}

.add-multiple-btn {
  margin-top: 0px;
  margin-bottom: 30px !important;
}

.form-control.is-invalid {
  border-color: #779be1;

  &:focus {
    border-color: #779be1;
    box-shadow: none;
  }
}

@media(max-width:1350px) {
  .employeeDetailCard {
    width: 14rem !important;
  }

  .subCardTitle {
    font-size: 13px;
    line-height: 18px;
  }

  .custom-card {
    width: 110px;
  }
}

.firstRounView {
  .required {
    color: red;
  }
}


.document-upload-wrapper {
  .mb-30 {
    margin-bottom: 30px;
  }

  .text-truncate {
    min-width: 220px;
    max-width: 220px;
    font-size: 14px;
  }

  .form-label {
    font-size: 14px;
  }
}

// .uploadDocuments__MainContainer-sc-186256r-0{
//   height: 100vh !important;
// }

.candidateformsec {
  .input-border {
    input {

      color: hsl(0, 0%, 20%) !important;

      &::placeholder {
        // color: hsl(0, 0%, 20%) !important;
      }
    }


  }
}

.work-top-card {
  background-color: #fff;
  // display: flex !important;
  padding: 15px 20px;
  border-radius: 8px;
  width: 250px;

  // gap: 10px;
  .work-card-value {
    font-size: 16px;
    color: #000;
    font-weight: 900;
  }
}

.login-left-text-main {
  color: #ffffff;
  font-size: calc(3vw - 10px);
  letter-spacing: 1px;
  margin-bottom: 0px;
  line-height: 32px !important;
}

.login-left-text-sub {
  color: #ffffff;
  font-size: 3vw;
  font-weight: 600;
}

.login-left-text-dot {
  color: #ffb633;
}

.w-80 {
  width: 80%;
}

.btn-addnew-custom {
  background-color: #fbb616;
  border-color: #fbb514;
  padding: 4px 10px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  height: 38px;
  min-width: 70px;
  max-width: 70px;
  color: #000;
  font-size: 14px;
  align-items: center;
}

.total-count-card {
  background-color: #f1eeee;
  padding: 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 900px;
  height: 50px;
  text-align: center;
  margin-bottom: 22px;
}

.total-inner-card {
  border-right: 3px solid #6f6f6f;
  padding-right: 8px;
}

.total-card-text {
  font-size: 16px;
  font-weight: 600;
}

.three-dot-custom {
  margin-top: 50px;

  .back_btn {
    font-size: 26px;
  }

  .dropdown_three_dot {
    &::after {
      display: none;
    }

    &:active {
      border: 0;
      box-shadow: none;
    }
  }

  // .btn-back {
  //   color: #000;
  // }
}

.breadcrumb-item-folderpath {
  // color: #0d6efd;
  // font-size: 14px;
  // font-weight: 500;
  cursor: pointer;
  text-decoration: underline;

  font-size: 16px;
  font-weight: 600;
  color: #013979;


}

.right_icon_folderpath {
  color: #0d6efd;
  font-size: 14px !important;
}

.folder-wraper {
  border: 1px solid #000;
  border-radius: 10px;
  // width: 140px;
  // height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  .folder-icon {
    width: 50px;
    height: auto;
  }

  .file_date_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    padding: 12px;

    .file_text {
      color: #000;
      font-size: 14px;
      font-weight: 500;

    }
  }
}

.file-wraper {
  border: 1px solid #000;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filename_wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px;
  }

  .filedetails_wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 6px 20px;
  }

  .file-icon {
    width: 50px;
    height: auto;
  }

  .file_date_wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .file_text {
      color: #000;
      font-size: 14px;
      font-weight: 500;

    }
  }
}

.folder-name {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  text-align: center;
  padding: 6px;
}

.file-name {
  // padding: 8px 26px;
}

.pos-rel {
  position: relative;

  .eye-wrapper {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #dc3545;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .wrapper-file-icon {
    position: absolute;
    top: 12px;
    left: 4px;
    color: #000;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .eye-wrapper-file {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #dc3545;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .eye-wrapper-custom {
    background-color: #e43e3e;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
}

.eye-wrapper-custom {
  background-color: #e43e3e;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.br_bottom {
  border-bottom: 1px solid #d2d4da;
  margin-bottom: 20px;
}

.dataTable-wrapper .folder_loading {
  width: 50px;
  height: 50px;
  object-fit: contain;
}



.file_repo_menu_list {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
  margin-bottom: 20px;

  .file_repo_menu_icon {
    cursor: pointer;

    .icon {
      color: #000;
      font-size: 22px;
      font-weight: 500;
    }
  }
}

.d_flex_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;


  .v-line {
    position: relative;

    &::after {
      background-color: #346194;
      content: "";
      height: 18px;
      position: absolute;
      right: -7px;
      width: 2px;
      top: 3px;
    }
  }
}

.form_question_array {
  display: flex;
  align-items: center;
}

.d_flex_terms {
  display: flex;
  align-items: center;
  gap: 8px;

  .form-check-input {
    border: 1px solid #a1a1a1 !important;
    min-height: 15px;
    padding: 2px 2px 2px 0px;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }
  }

  .form-check-input:checked {
    background-color: #1f3a6e;
    border-color: #1f3a6e !important;
  }

  .text_link {
    color: #013979;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
}

.announcement-description-modal {
  .modal-dialog {
    max-width: 800px !important;
  }

  .modal-header {
    border-bottom: none !important;
  }

  .modal-body {
    padding-top: 0px !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    li,
    p {
      margin: revert !important;
      padding: revert !important;
    }
  }

  .modal-title.h4 {
    font-size: 22px;
  }
}

.terms_conditions_modal {

  .modal-title {
    color: #000;
    font-size: 18px;
    font-weight: 600;
  }

  .save-btn {
    padding: 3px 10px;
    font-size: 14px;
    border-radius: 5px !important;
    min-width: 110px !important;
    color: #f3f3f3 !important;
    border-color: #013979 !important;
    background-color: #013979 !important;
  }

  .btn-close {
    &:focus {
      box-shadow: none;
    }
  }
}

.update_wrapper_settings {
  display: flex;
  align-items: center;
  gap: 8px;

  .dotted-input-settings {
    border: 1px dashed #d2d4da;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: hsl(0, 0%, 20%);

    &:focus {
      box-shadow: none;
    }
  }

  .update_settings {
    color: #19b119;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .close_settings {
    color: #db1818;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
  }
}


.disabled-datepicker {
  background-color: var(--bs-secondary-bg);
  /* Custom background color */
  opacity: 1;
  /* Ensure full opacity */
  cursor: not-allowed;
  /* Optional: Show a not-allowed cursor */
}

/* Add additional styling if needed */
.disabled-datepicker input {
  background-color: var(--bs-secondary-bg);
  cursor: not-allowed;
}

.clear_selected_items {
  background-color: #013979;
  padding: 0px 30px;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 550;
  border-radius: 10px;
  color: #ffffff;
}



.login-page {
  .reset-wrapper {
    .left-block {
      .anonymous_complaint {
        position: absolute;
        bottom: 20px;
        left: 50px;
        width: 100%;

        .complaint_icon {
          width: 40px;
          height: 40px;
          object-fit: contain;
          cursor: pointer;
        }
      }
    }
  }
}

.add_delete_holiday {
  display: flex;
  align-items: center;
  gap: 4px;

  .add_icon {
    color: #013979;
    font-size: 24px;
    font-weight: 600;
  }

  .delete_icon {
    color: #e84646;
    font-size: 24px;
    font-weight: 600;
  }
}


.listing-form {
  .mb-3 {
    margin-bottom: 25px !important;
  }

  .instructionLable {
    display: flex;
  }

  label {
    font-size: 14px;
    font-weight: 700;
    color: #000;

    .required {
      color: red;
    }
  }

  .warning_icon {
    img {
      width: 13px;
      margin-left: 5px;
      margin-top: -3px;
    }
  }

  .dotted-input-half {
    border: 1px dashed #d2d4da;
    border-radius: 0;
    position: relative;
    border-left-color: #fff;
    margin-left: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    color: hsl(0, 0%, 20%);

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: 14px;
      color: #696a72;
    }
  }

  .input-border {
    position: relative;

    &:before {
      position: absolute;
      content: "";
      height: 40px;
      width: 3px;
      background-color: #698ab0;
      left: 0;
      top: 0;
    }
  }

  .option-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    .form-check-input {
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(1 57 121 / 16%);
      }

      &:checked {
        background-color: #013979;
        border-color: #485c73;
      }
    }
  }

  .add-more-wrapper {
    .add_more {
      color: #013979;
      font-size: 14px;
      display: flex;
      gap: 10px;
      align-items: center;
      // cursor: pointer;
      margin-bottom: 15px;

      img {
        width: 15px;
        margin-top: -4px;
      }

      span {
        cursor: pointer;
      }
    }
  }

  .other-lebel {
    color: #013979;
  }

  .other-input {
    border: 1px dashed #698ab0;
    border-left: 0;
  }

  .css-1dimb5e-singleValue {
    font-size: 14px;
  }

  .address-title {
    font-size: 20px;
    padding-bottom: 30px;
    color: #013979;
  }

  .residential-address-title {
    padding-bottom: 30px;
    display: flex;
    gap: 20px;
    align-items: center;

    h3 {
      font-size: 20px;
      color: #013979;
      padding-bottom: 10px;
    }

    .form-check-label {
      font-weight: 500 !important;
    }

    .form-check-input {
      border: 1px solid #d2d4da;
      border-radius: 2px;
      cursor: pointer;

      &:focus {
        box-shadow: 0 0 0 3px rgba(178, 181, 185, 0.25);
      }
    }

    .form-check-input:checked {
      background-color: #698ab0;
      border-color: #698ab0;
    }
  }
}

.dataTable-wrapper {
  .shift-refresh {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1215686275);
    padding: 0px 20px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.5411764706);
  }
}

.modal_change_status {
  h2 {
    max-width: 100%;
    margin: 0px 0px 0.4em;
    padding: 0px;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    text-transform: none;
    overflow-wrap: break-word;
  }
}

.history_data {
  .history_title {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px;

  }

  .main_wrapper {
    .history_text {
      color: #000;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 4px;

      .history_name {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }
    }
  }
}

.sync_data_rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.icon_box_checkbox {
  color: #fdb712;
  font-size: 20px;
}


.status_wrap_datatable {
  .form-check-input {
    height: 24px;
    width: 52px;

    &:checked {
      background-color: #013979;
      border: 0;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.flex_right_wrapper_assessment {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.table_question_answer {
  table {
    thead {
      tr {
        th {
          color: #242529;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    tbody {
      tr {
        td {
          .input {
            color: #000000;
            font-size: 14px;
            font-weight: 600;
            padding-right: 10px;
          }
        }
      }
    }
  }
}


.bg_color_review_highlight {
  // background-color: #013979;
  // border-radius: 15px;
  // box-shadow: 0 0 10px -1px #ededed;
  padding: 20px;

  .single-grid {
    .label {
      color: #fff !important;
    }

    .input {
      color: #fff !important;
    }
  }
}

.total_user_selected {

  .profile-name {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #999292;
    font-size: 14px;
    font-weight: 500;

    .empid {
      color: #000;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.d_flex_btn_evaluated {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 12px;

  .btn-groups {
    margin-top: 0;
  }
}


.category-modal {
  .content_noc_details {
    h3 {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;

      .text-content {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 6px;
      }
    }
  }
}


.waiting_result {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 12px;
}

.card1-bg-color {
  box-shadow: 0 0 20px rgb(0 26 71 / 8%);
  border: none;
  height: 100%;
  border-radius: 5px;
  padding: 20px;
  background: #f5f5f5;
}

.card1-bg-color_noback {
  border: none;
  height: 100%;
  border-radius: 5px;
  padding: 12px;
  margin-right: 20px;
  border: 1px solid #f5f5f5;
  background: #f5f5f5;
}

.grid_template_answers {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;

}

.no-data-found-text {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}

.no-data-found-text-performance {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  display: flex;
  margin-bottom: 6px;
  margin-top: 12px;
}

.d_flex_answers {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}


.assign_wrapper {
  .probation-wrapper {
    display: flex;
    gap: 20px;

    .probation-option {
      .form-check-label {
        color: #707070;
      }

      .form-check-inline {
        padding-top: 10px;

        .form-check-input {
          border: 1px solid #d2d4da;
          border-radius: 2px;

          &:focus {
            box-shadow: none;
          }
        }

        .form-check-input:checked {
          background-color: #013979;
          border-color: #013979;
        }
      }
    }

    .probation-duration {
      color: #707070;

      .css-1fdsijx-ValueContainer {
        min-width: 70px;
      }
    }

    .css-10lk5e0-control {
      background-color: #eeeeee63;
      border-color: #e9e5e563;
      border-style: dashed;
      min-height: 40px;
      margin-left: 3px;
    }

    .css-1dimb5e-singleValue {
      font-size: 14px;
    }

    .css-olqui2-singleValue {
      font-size: 14px;
    }
  }
}

.direct-candidate-check {
  .form-check {
    .form-check-input {
      &:focus {
        box-shadow: none;
      }

      &:checked {
        background-color: #013979;
        border-color: #013979;
      }

    }

  }
}


.tab_container_candidates {
  margin-top: 50px;

  .bg_box_shadow_Candidate {
    background: #fff;
    box-shadow: 0 0 20px rgba(1, 41, 112, .08);
    border-radius: 5px;
    padding: 6px;
    width: fit-content;
  }

  .nav-pills {
    .nav-link {
      color: #000;
      font-size: 14px;
      font-weight: 600;

      &.active {
        color: #fff;
        background-color: #013979;
      }
    }
  }
}

.invalid-check-questions .form-check-input {
  border: 1px solid red !important;
  outline: none;
}

.probation-wrapper {
  display: flex;
  gap: 20px;

  .probation-option {
    .form-check-label {
      color: #707070;
    }

    .form-check-inline {
      padding-top: 10px;

      .form-check-input {
        border: 1px solid #d2d4da;
        border-radius: 2px;

        &:focus {
          box-shadow: 0 0 0 3px rgb(178 181 185 / 25%);
        }
      }

      .form-check-input:checked {
        background-color: #698ab0;
        border-color: #698ab0;
      }
    }
  }

  .probation-duration {
    color: #707070;

    .css-1fdsijx-ValueContainer {
      min-width: 70px;
    }
  }

  .css-10lk5e0-control {
    background-color: #eeeeee63;
    border-color: #e9e5e563;
    border-style: dashed;
    min-height: 40px;
    margin-left: 3px;
  }

  .css-1dimb5e-singleValue {
    font-size: 14px;
  }

  .css-olqui2-singleValue {
    font-size: 14px;
  }
}

.correct_answers {
  color: #013979;
  font-size: 16px;
  font-weight: 600;
  // margin-top: 12px;
}

.custom-attendance-swal-title h2 {
  font-size: 22px !important;
}


.job-search-body {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  padding: 20px 12px;

  .job-card {
    position: relative;
    display: block;
    padding: 20px 0;
    width: calc(19.3% - 15px);
    min-height: 300px;
    text-decoration: none;
    border-radius: 10px;
    background-color: #fff;
    border: 2px solid #fff;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    transition: transform .5s;
    transform: none;

    @media (max-width: 1400px) {
      width: calc(50% - 15px);
    }

    .featured-logo {
      display: none;
      position: absolute;
      top: -1px;
      right: -1px;
      padding: 3px 12px 3px 10px;
      height: 20px;
      color: #fff;
      font-weight: 400;
      line-height: 13px;
      font-style: italic;
      font-size: 12px;
      letter-spacing: 0.5px;
      background-color: #0b4c8f;
      border-radius: 0 10px 0px 0;
    }

    .head-sec {
      display: flex;
      flex-direction: row;
      padding: 0 20px;

      .company-logo {
        display: inline-block;
        width: 60px;
        height: 60px;
        border: 1px solid #e3e4f5;
        border-radius: 50px;
        overflow: hidden;
      }

      .name-location-wrap {
        width: calc(100% - 12px);

        .name-savejob-wrap {
          display: flex;

          .save-job {
            margin-left: 10px;
            margin-top: 2px;
            cursor: pointer;

            i {
              font-size: 16px;
            }

            .bi-heart {
              color: grey;
            }

            .bi-heart-fill {
              display: none;
              color: #0040ab;
            }

            &.saved-job {
              .bi-heart {
                display: none;
              }

              .bi-heart-fill {
                display: block;
              }
            }
          }
        }

        .company-name {
          display: flex;
          width: calc(100% - 20px);
          font-size: 18px;
          color: #000;
          font-weight: 600;
          text-decoration: none;
          transition: 0.3s;

          &:hover {
            color: #000;
          }

          .company-name-text {
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          .verified-company {
            display: none;

            img {
              display: block;
              margin: 6px 0 0 5px;
              width: 16px;
              height: 16px;
            }
          }

          .peza-company {
            display: none;

            img {
              display: block;
              margin: 6px 0 0 5px;
              width: 18px;
              height: 18px;
            }
          }
        }

        .company-location {
          position: relative;
          font-size: 14px;
          color: #000;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;

        }
      }
    }

    .body-sec {
      margin-top: 15px;
      padding: 0 20px;

      .job_post_icon {
        color: #013979;
        font-size: 16px;
        font-weight: 600;
        margin-right: 6px;
      }

      h3 {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        color: #000612;
        font-size: 18px;
        font-weight: 600;
        text-decoration: none;
        margin: 12px 0;
        line-height: 24px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;

        .job-title {
          display: -webkit-box;
          width: calc(100% - 25px);
          padding-right: 5px;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .wfh-job {
          display: none;

          img {
            margin: 0 0 0 0;
            width: 18px;
            height: 18px;
          }
        }

        .wfi-job {
          display: none;

          img {
            margin: 0 0 0 5px;
            width: 18px;
            height: 18px;
          }
        }

        .govt-job {
          display: none;

          img {
            margin: 0 0 0 5px;
            width: 18px;
            height: 18px;
          }
        }
      }

      .job-details-wrap {
        span {
          display: block;
          position: relative;
          margin-bottom: 3px;
          font-size: 14px;
          color: #000;
          font-weight: 400;

        }
      }

      p {
        margin-top: 15px;
        height: 50.8px;
        color: #000;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .footer-sec {
      position: absolute;
      width: 100%;
      bottom: 20px;
      margin-top: 20px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .btn-apply-job {
        background-color: #013979 !important;
        border-color: #013979 !important;
        color: #f3f3f3 !important;
        border-radius: 30px;
        padding: 6px 30px;
      }

      .btn-apply-job-yellow {
        background-color: #fbb514 !important;
        border-color: #fbb514 !important;
        color: #f3f3f3 !important;
        border-radius: 30px;
        padding: 6px 30px;
      }

      .btn-primary {
        float: right;

        &.disabled {
          background-color: #9a9b9b;
        }
      }

      .applied-count-wrap {
        position: relative;
        float: right;

        span {
          &.app-cand-img-wrap {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            border: 2px solid #fff;
            overflow: hidden;

            &.over-img-1 {
              position: absolute;
              top: 0;
              right: 20px;
              z-index: 2;
            }

            &.over-img-2 {
              position: absolute;
              top: 0;
              right: 42px;
              z-index: 1;
            }

            &.over-count {
              position: absolute;
              top: 0;
              right: 0;
              color: #fff;
              font-size: 14px;
              line-height: 34px;
              font-weight: 600;
              text-align: center;
              background-color: #35b3e5;
              z-index: 3;
            }
          }
        }
      }
    }

    .urgent-hiring-wrap {
      display: none;
    }

    &.verified-company {
      .head-sec .name-location-wrap .company-name .verified-company {
        display: block;
      }
    }

    &.peza-company {
      .head-sec .name-location-wrap .company-name .peza-company {
        display: block;
      }
    }

    &.wfh-job {
      .body-sec h3 .wfh-job {
        display: block;
      }
    }

    &.wfi-job {
      .body-sec h3 .wfi-job {
        display: block;
      }
    }

    &.govt-job {
      .body-sec h3 .govt-job {
        display: block;
      }
    }

    &.featured-job {
      border: 2px solid #0b4c8f;

      .featured-logo {
        display: block;
      }
    }

    &.urgent-hiring {
      .applied-count-wrap {
        display: none;
      }

      .urgent-hiring-wrap {
        display: block;
        position: absolute;
        top: 10px;
        left: 20px;
        color: #ec4040;
        font-style: italic;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: .5px;

        img {
          float: left;
          margin-right: 3px;
          width: 18px;
          height: 18px;
          animation: jiggle .2s infinite;
          -webkit-animation: jiggle .2s infinite;
          -moz-animation-duration: .2s;
          -moz-animation-name: jiggle;
          -moz-animation-iteration-count: infinite;
          -webkit-transform: rotate(-3deg);
          -moz-transform: rotate(-3deg);
        }
      }
    }
  }
}

.ck-editor-box {
  .main-container {
    .ck-content.ck-editor__editable {
      min-height: 300px !important;
      max-height: 300px !important;
      overflow-y: scroll;

      ul {
        li {
          margin-left: 34px !important;
        }
      }
    }
  }
}



.pagination-cust {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  list-style: none;
  padding: 0;
  margin-top: 20px;
}

.pagination-cust .active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #013979 !important;
  color: #fff;
  border-color: #013979;
  border-radius: 25px;
}

.invalid-feedback {
  font-size: 12px;
}

.page-item {
  margin: 0 2px;
}

.page-link {
  display: inline-block;
  // padding: 8px 12px;
  border: 0;
  // background-color: #fff;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}

.page-link:focus {
  box-shadow: none;
}


.page-link:hover {
  background-color: #f0f0f0 !important;
  border-radius: 50%;
}

.disabled>.page-link,
.page-link.disabled {
  background-color: transparent !important;
}

.page-link:hover {
  background-color: transparent !important;
}

.active-item .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #013979 !important;
  color: #fff;
  border-color: #013979;
  border-radius: 25px;
}

.disabled .page-link {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled .page-link:focus {
  box-shadow: none;
}

.mark_as_read {
  color: #f3f3f3 !important;
  border-color: #013979 !important;
  background-color: #013979 !important;
  padding: 2px 6px;
  font-size: 11px;
  border-radius: 5px !important;
}

.dflex_wrapper_questions {
  display: flex;
  justify-content: space-between;
}

.max_width_400 {
  max-width: 400px;
}

.bg_color_review_highlight_padding {
  background-color: #01397970;
  border-radius: 15px;
  box-shadow: 0 0 10px -1px #01397970;
  padding: 12px 20px;

  .single-grid {
    .label {
      color: #000 !important;
    }

    .input {
      color: #000 !important;
    }
  }
}

.timeline-file {
  display: flex;
  gap: 6px;
  margin-top: 12px;

  .icon {
    font-size: 24px;
  }

  .file_name {
    color: #0d6efd;
  }
}

.heading_text_wrapper {
  text-align: center;

  h5 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }
}

.highlight-row {
  --bs-table-bg-type: #fbb514 !important;
  font-weight: bold;
}

.main-content-wrapper {
  .logo_wrapper_document_view {
    width: 80px;
  }

  .btn_wrapper_document_view {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }
}

.day-absent {
  background-color: red !important;
  /* For Absent days */
}

.day-present {
  background-color: green !important;
  /* For HD and FD days */
}

/* Optional: Default style for cells without events */
.fc-daygrid-day {
  // background-color: #edfaed;
  /* Default background color */
}

// error page
.errorPage {
  position: relative;
  z-index: 100;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .img-wrapper {
    text-align: center;

    img {
      width: 400px;

      @media (max-width: 575px) {
        width: 300px;
      }
    }
  }

  .content {
    text-align: center;

    h6 {
      font-size: 20px;
      padding-top: 40px;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      padding: 10px 0px;
    }

    .btn_wrapper {
      align-items: center;

      .webinar_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        background-color: #4040a8;
        border: 0;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        padding: 8px 24px;
        text-decoration: none;
        transition: all 0.4s ease-in-out;
        width: 250px;

        .bg_arrow_icon {
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 4px;
          width: 32px;
          height: 32px;
          transition: all 0.4s ease-in-out;

          svg {
            color: #000;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

.lengends_structure_overview {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 30px;

  .legends_view {
    display: flex;
    align-items: center;
    gap: 6px;

    .short_name {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      padding: 6px 14px;
    }

    .present {
      background-color: #edfaed;
    }

    .present-holiday {
      background-color: #660f8d;
    }

    .present {
      background-color: #edfaed;
    }

    .absent {
      background-color: #ffedf2;
      color: #f10d0d;
    }

    .off_day {
      background-color: #ededed;
    }

    .leave {
      background-color: #fdf4fd;
    }

    .today {
      background-color: rgba(255, 220, 40, .15);
      width: 39px;
      height: 36px;
    }

    .unleave {
      background-color: rgb(211 64 12 / 20%);
    }

    .unapprovedhalfdayleave {
      background: linear-gradient(to bottom, rgba(211, 64, 12, 0.2) 50%, #edfaed 50%);
    }

    .approvedhalfdayleave {
      background: linear-gradient(to bottom, #ffedf2 50%, #edfaed 50%);
    }

    .holiday {
      background-color: #ebebeb;
    }

    .full_name {
      color: #657381;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
      max-width: 120px;
    }


  }
}

/* Background color for cells with Absent events */
.fc-daygrid-day-absent {
  background-color: #ffedf2 !important;
  /* Light red */
  color: #721c24 !important;
}

/* General structure for split color cells */
.fc-daygrid-day-split-absent-present {
  background: linear-gradient(to bottom, #ffedf2 50%, #edfaed 50%);
  /* Top half red for Absent, bottom half green for Present */
}

/* General structure for split color cells */
.fc-daygrid-day-split-ualeave {
  background: linear-gradient(to bottom, rgb(211 64 12 / 20%) 50%, #edfaed 50%);
  /* Top half red for Absent, bottom half green for Present */
}

/* General structure for split color cells */
.fc-daygrid-day-leave-present {
  background: linear-gradient(to bottom, #ffedf2 50%, #edfaed 50%);
  /* Top half red for Absent, bottom half green for Present */
}

.fc-daygrid-day-split-present-holiday {
  background: linear-gradient(to bottom, #edfaed 50%, #ffedf2 50%);
  /* Top half green for Present, bottom half orange for Holiday */
}

/* Background color for cells with Present (HD or FD) events */
.fc-daygrid-day-present {
  background-color: #edfaed !important;
  /* Light green */
  color: #155724 !important;
}

.fc-daygrid-day-holiday {
  background-color: #ebebeb !important;
  /* Light red */
  color: #721c24 !important;
}

.fc-daygrid-day-leave {
  background-color: #fdf4fd !important;
  /* Light red */
  color: #721c24 !important;
}

.fc-daygrid-day-ualeave {
  background-color: rgb(211 64 12 / 20%) !important;
  /* Light red */
  color: #721c24 !important;
}

.selected-date-highlight {
  border: 3px solid #86d0f0 !important;
  background-color: #e0f7fa !important;
}

.apply_ot_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  .apply_ot_left_wrapper {
    display: flex;
    align-items: center;

    .icon {
      color: red;
      font-size: 22px;
      margin-right: 6px;
    }

    h4 {
      color: #000;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .apply_ot_right_wrapper {
    .apply_btn_ot {
      color: #0077ff;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
    }
  }
}

.no-data-message {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 12px;
}

.shift_details_attendance_log {
  h5 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;

    .punch_title {
      color: #013979;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
}

.sticky_header_appraisal {
  position: sticky;
  top: 60px;
  z-index: 2;
}

.d_flex_appraisal_wrapper {
  display: flex;
  gap: 20px;
}

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.appraisal_service_modal {
  position: absolute;
  top: 50%;
  left: 30%;
  display: flex;
  flex-direction: column;
  // width: 100%;
  /* Adjust width as needed */
  width: 750px;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
  z-index: 1001;
  border: 1px solid #d2d4da;
  max-height: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  /* Ensure it is above the overlay */

  .vertical-timeline-element-icon {
    width: 20px !important;
    height: 20px !important;
    left: 54% !important;
    margin-left: -34px !important;
  }

}

.no-toggle .accordion-button::after {
  display: none;
}

.regularization_approved_icon {
  position: absolute;
  bottom: -10px;
  left: 5px;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid #8399ff;
  transform: rotate(-90deg);
}

.overtime_approved_icon {
  position: absolute;
  bottom: -10px;
  left: 5px;
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid #dd79db;
  transform: rotate(-90deg);
}

.regularization_approved_icon_legends {
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid #8399ff;
  transform: rotate(-90deg);
}

.overtime_approved_icon_legends {
  width: 0;
  height: 0;
  border-right: 20px solid transparent;
  border-top: 20px solid #dd79db;
  transform: rotate(-90deg);
}

.session_leave_attendance {
  display: flex;
  gap: 20px;
}


.bg_color_emp_wrapper {
  background-color: #f4f7ff;
}

.emp_wrapper_grid_3 {
  grid-template-columns: repeat(3, 1fr) !important;
}

.emp_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 12px;
  gap: 12px;

  .emp_data_details {
    border: 1px dashed #d2d4da;
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;

    h4 {
      font-size: 14px;
      font-weight: 600;
    }

    .emp_details {
      font-size: 14px;
      font-weight: 600;
      background-color: #013979;
      color: #fff;
      padding: 4px 8px;
      border-radius: 6px;
    }

    .emp_details_bg_none {
      font-size: 14px;
      font-weight: 600;
      color: #000;
      border-radius: 6px;
    }
  }

  .emp_data_details_w-50 {
    width: 50%;
  }

  .emp_data_details_performance {
    border: 1px dashed #d2d4da;
    border-radius: 12px;
    padding: 8px 12px;

    .performance-table {
      font-size: 13px;
      width: 100%;

      tbody {
        tr {
          td {
            padding: 6px;
          }
        }
      }
    }

    .emp_data_details_performance_flex_breadcrumb {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-size: 14px;
        font-weight: 600;
      }

      .border_box {
        color: #ffffff;
        font-size: 13px !important;
        font-weight: 800 !important;
        background-color: #013979;
        border-radius: 50%;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .cur_none {
        cursor: text;
      }

    }

    .emp_data_details_performance_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h4 {
        font-size: 14px;
        font-weight: 600;
      }

      .border_box {
        color: #ffffff;
        font-size: 13px !important;
        font-weight: 800 !important;
        background-color: #013979;
        border-radius: 50%;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    .emp_details {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .accordion-header {
    .accordion-button {
      background-color: #f4f7ff;
      padding: 6px 12px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: 1px solid #fff;

      &:focus {
        box-shadow: none;
      }
    }
  }


  .sticky_header_appraisal {
    position: sticky;
    top: 60px;
    z-index: 2;
  }

  .d_flex_appraisal_wrapper {
    display: flex;
    gap: 20px;
  }

  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .appraisal_service_modal {
    position: absolute;
    top: 50%;
    left: 30%;
    display: flex;
    flex-direction: column;
    // width: 100%;
    /* Adjust width as needed */
    width: 750px;
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    z-index: 1001;
    border: 1px solid #d2d4da;
    max-height: 550px;
    overflow-y: auto;
    /* Ensure it is above the overlay */

    .vertical-timeline-element-icon {
      width: 20px !important;
      height: 20px !important;
      left: 54% !important;
      margin-left: -34px !important;
    }

  }

  .no-toggle .accordion-button::after {
    display: none;
  }

  .regularization_approved_icon {
    position: absolute;
    bottom: -10px;
    left: 5px;
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-top: 20px solid #8399ff;
    transform: rotate(-90deg);
  }

  .overtime_approved_icon {
    position: absolute;
    bottom: -10px;
    left: 5px;
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-top: 20px solid #dd79db;
    transform: rotate(-90deg);
  }

  .regularization_approved_icon_legends {
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-top: 20px solid #8399ff;
    transform: rotate(-90deg);
  }

  .overtime_approved_icon_legends {
    width: 0;
    height: 0;
    border-right: 20px solid transparent;
    border-top: 20px solid #dd79db;
    transform: rotate(-90deg);
  }

  .session_leave_attendance {
    display: flex;
    gap: 20px;
  }
}

.delete_icon_wrapper_document {
  .icon {
    color: red;
    font-size: 22px;
    cursor: pointer;
  }

}

.mail_icon_wrapper_multiple {
  .icon {
    color: #000;
    font-size: 22px;
    cursor: pointer;
  }

  .delete_icon_wrapper_document {
    .icon {
      color: red;
      font-size: 22px;
      cursor: pointer;
    }

  }

  .mail_icon_wrapper_multiple {
    .icon {
      color: #000;
      font-size: 22px;
      cursor: pointer;
    }
  }
}

.emp_wrapper_flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.close_btn_wrapper_appriasal {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .icon {
    color: #000;
    font-size: 30px;
    font-weight: 600;
  }
}

.input-border {
  .hour_select {
    border: 1px dashed #d2d4da;
    border-radius: 0;
    position: relative;
    border-left-color: #fff;
    margin-left: 5px;
    // border-right: 0;
    font-size: 14px;
    color: hsl(0, 0%, 20%);
    height: 40px;

    &:focus {
      box-shadow: none;
    }
  }

  .minutes_select {
    border: 1px dashed #d2d4da;
    border-radius: 0;
    position: relative;
    border-left-color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    color: hsl(0, 0%, 20%);
    height: 40px;

    &:focus {
      box-shadow: none;
    }
  }
}

.no_data_found {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}


.table-nested {
  margin-top: 8px;
  padding: 12px;
  background-color: #f4f5fa;
  box-shadow: 0 0 12px -1px rgba(0, 0, 0, 0.11);

  .rdt_TableHead {
    .rdt_TableHeadRow {
      background-color: #ffff !important;
    }
  }

}

.selected-row {
  height: 30px !important;
  z-index: 1 !important;
  background-color: blue !important;
}

.default-row {
  height: 30px !important;
  z-index: 1 !important;
}

// ORG Chart
.layout-container,
#react-org-chart {
  height: 100vh;
  width: 100%;
  position: relative;
}

.org-chart-person-name {
  color: #000;
  font-size: 12px;
  font-weight: 500;
}

.org-chart-person-link:hover g {
  fill: #409cf9 !important;
}

// .org-chart-node rect {
//   fill: #fff !important;
//   /* Light blue fill color */
//   stroke: #ffff !important;
//   /* Blue border */
//   stroke-width: 2px;
//   /* Border width */
//   border-radius: 0px;
// }

.org-chart-node:hover .org-chart-person-reports {
  fill: #409cf9 !important;
}

.org-chart-person-dept.engineering {
  fill: #4caf50 !important;
}

.org-chart-person-dept.communications {
  fill: #3f51b5 !important;
}

.org-chart-person-dept.product {
  fill: #d500f9 !important;
}

.org-chart-person-dept.hr {
  fill: #2196f3 !important;
}

.org-chart-person-dept.marketing {
  fill: #f44336 !important;
}

.org-chart-person-dept.design {
  fill: #26c6da !important;
}

.org-chart-avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  /* Optional: make it circular */
}

.zoom-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.download-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
}

// .zoom-button {
//   width: 40px;
//   height: 40px;
//   display: block !important;
//   margin: 8px;
// }

.btn-zoom {
  font-size: 0.875rem;
  text-transform: none;
  text-decoration-line: none;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  margin: 6px;
}

.btn-outline-primary {
  background-color: #4273d3 !important;
  border-color: #779be1 !important;
  color: #fff !important;
  border: 0;
  border-radius: 6px;
  padding: 8px 16px;
  line-height: 20px;
}


.download-image-btn {
  color: #f3f3f3 !important;
  border-color: #013979 !important;
  background-color: #013979 !important;
  border-radius: 5px !important;
  font-size: 14px;
  min-width: 110px !important;
  padding: 3px 10px;
}

.download-pdf-btn {
  background-color: #4273d3 !important;
  border-color: #779be1 !important;
  color: #fff !important;
  border-radius: 5px !important;
  font-size: 14px;
  min-width: 110px !important;
  padding: 3px 10px;
}


.download-excel-btn {
  background-color: #fff !important;
  border-color: #4273d3 !important;
  color: #4273d3 !important;
  border-radius: 5px !important;
  font-size: 14px;
  min-width: 110px !important;
  padding: 3px 10px;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #374ea2;
  border-color: #374ea2;
}

.github-link {
  font-size: 16px;
  margin-left: 8px;
  margin-right: 16px;
}

#react-org-chart image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  /* Optional: make it circular */
}


.all-reportee-input {
  padding: 26px;
  text-align: center;
  margin-bottom: 10px;

  .no_data_found_wrapper {

    h5 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 6px;
    }

    .show-message {
      color: #6a6767;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.mass-assign .form-check .form-check-input:checked {
  background-color: #1f3a6e;
  border-color: #1f3a6e !important;
}

.mass-assign .form-check .form-check-input:focus {
  box-shadow: none;
}

.modal-content-wrapper {
  padding: 12px 0px 0px 0px;

  .qr-code-modal-header {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-qr {
      width: 350px;
      height: 90px;
    }

  }

  .qr-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
    position: relative;
    z-index: 3;

    p {
      color: #20487d;
      font-size: 20px;
      font-weight: 500;
      width: 500px;
      text-align: center;
    }

    .form-control {
      border: 0;
      color: #20487d;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      padding: 0;

      &:hover {
        box-shadow: none;
      }

      &:active {
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .text_bold_wrapper {
      color: #20487d;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }
  }


  .qr-modal-body-wrapper {


    position: relative;
    margin: 90px 0;

    .qr-modal-body-section-1 {
      position: relative;
      display: flex;
      justify-content: center;
      // padding: 20px;
      z-index: 2;
    }

    .qr-modal-body-section-2 {
      position: absolute;
      z-index: 1;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .qr-modal-footer-wrapper {
    position: relative;

    .qr-modal-footer-section-1 {
      position: relative;
    }

    .qr-modal-footer-section-2 {
      height: 230px;
      position: absolute;
      right: 0;
      bottom: 0;

    }
  }
}

.modal-90w {
  max-width: none !important;
  width: 90%;
}

.breadcrumb-item {
  cursor: pointer;
  margin-right: 5px;
  text-decoration: underline;
}

.breadcrumb-item.current {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  cursor: default;
}

.breadcrumb {
  padding: 0px 18px;

  .breadcrumb-item-folderpath {
    color: #0d6efd;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;


  }

  .right_icon_folderpath {
    color: #0d6efd;
    font-size: 14px !important;
  }
}

.candidate-form-pop-up-header {
  .alert-card {
    background-color: #ffff;
    // border: 1px solid darkgrey;
    // box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    // box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 10px;
    border-radius: 8px;
  }

  .request-message {
    color: rgb(16, 38, 93);
    font-size: 16px;
    font-weight: 600;
  }


  h2 {
    color: #f44336;
    padding-bottom: 10px;
    font-size: 1.1em;
    font-weight: 600;
    word-wrap: break-word;
  }

  @media(max-width: 480px) {
    h2 {
      padding: 0.8em 1em;
      font-size: 1em;
    }
  }

  .timeline-item {
    margin-bottom: 5px;
  }

  .time-line-container {
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;

    .history-title {
      color: rgb(16, 38, 93);
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 8px;
    }
  }
}

/*===========================
candidate form drop down alert
==============================*/
.three-dot-container {
  position: relative;
  // display: inline-block;

  .three-dot-dropdown {
    // top: 100%;
    // right: 0;
    width: 250px;
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    background-color: white;
    padding: 10px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 8px 12px;

        .three-dot-dropdown-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          text-decoration: none;

          .eye-wrapper {
            position: relative;
            background-color: #f5f7f9;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.14);
          cursor: pointer;
        }
      }

    }
  }
}

/*==================================
candidate form drop down alert end
===================================*/

.emp_details_main_wrapper_flex {
  display: flex;
  align-items: center;
  gap: 12px;
}

.btn_groups_cust {
  justify-content: flex-start !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  padding: 0 !important;
}

.margin_top_data {
  margin-top: 12px;

  .dataTable-header {
    margin-bottom: 0px !important;
  }
}

.emp_details_total_score {
  display: flex;
  justify-content: center;
  align-items: center;

  .emp_details_bg {
    background-color: #fbb616;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 12px;
  }
}

.text_truncate {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.performance-table .tooltip-container {
  position: relative;
  max-width: 190px;
  /* Adjust as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.performance-table .tooltip-container .tooltip-text {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.performance-table .tooltip-container .tooltip-text:hover::after {
  content: attr(title);
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 5px;
  z-index: 10;
  white-space: normal;
  max-width: 300px;
  /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn_groups_cust_m_0 {
  margin-top: 0 !important;

  .view_more_btn {
    display: flex;
    justify-content: flex-end;
    color: #003a78;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 20px;
    margin-top: 12px;
    margin-bottom: 12px;

    &:active {
      background-color: transparent;
      color: #003a78;
    }
  }

  .view_less_btn {
    display: flex;
    justify-content: flex-end;
    color: #003a78;
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-top: 12px;
    margin-bottom: 12px;

    &:active {
      background-color: transparent;
      color: #003a78;
    }
  }
}

.performance_close_wrapper {
  .close_icon {
    color: #000;
    font-size: 26px;
    cursor: pointer;
  }
}

.jus_end {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .add_more {
    cursor: pointer;
  }
}

.bg_appraisal_wrapper {
  background-color: #f6f8fa;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 20px;
}

.delete-sec-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .delete-sec-appraisal {
    display: flex;
    align-items: center;
    font-size: 13px;
    gap: 10px;
    background-color: #fff;
    padding: 2px 7px;
    border-radius: 5px;
    cursor: pointer;
    width: fit-content;
    margin-top: 12px;

  }
}

.pad-22 {
  padding: 22px;
}


.category-modal {
  .form-check-input {
    &:checked {
      background-color: #1f3a6e;
      border-color: #1f3a6e !important;
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.green_target {
  color: #13a313 !important;
}

.red_target {
  color: red !important;
}

.btn_emp_light {
  button {
    background-color: #F8CEB6 !important;
    color: #000 !important;
  }
}

.timeline-comment_ul {
  ul {
    padding-left: 22px;
  }
}

.border-danger {
  .form-check-input {
    border-color: red;
  }
}

.mar_top_30 {
  margin-top: 50px;
  margin-bottom: 20px;
}

.placeholder_custom {
  .form-control {
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      font-size: 13px;
    }
  }

}